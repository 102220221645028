import { route } from 'quasar/wrappers'
import {
	createMemoryHistory,
	createRouter,
	createWebHashHistory,
	createWebHistory,
} from 'vue-router'
import { pinia } from 'src/stores'
import { routes } from './routes'
import { useAuthStore } from 'stores/auth.store'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
	const createHistory = process.env.SERVER
		? createMemoryHistory
		: process.env.VUE_ROUTER_MODE === 'history'
			? createWebHistory
			: createWebHashHistory

	const Router = createRouter({
		scrollBehavior: () => ({ left: 0, top: 0 }),
		routes,

		// Leave this as is and make changes in quasar.conf.js instead!
		// quasar.conf.js -> build -> vueRouterMode
		// quasar.conf.js -> build -> publicPath
		history: createHistory(
			process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE,
		),
	})

	Router.beforeEach((to, from, next) => {
		const authStore = useAuthStore(pinia)

		if (to.path === '/auth/login' && authStore.isLoggedIn) {
			return next('/personal')
		}

		if (!authStore.isLoggedIn && to.meta.needAuth) {
			return next('/auth/login')
		}

		return next()
	})

	return Router
})
