/* tslint:disable */
/* eslint-disable */
/**
 * api-user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountProvider
 */
export interface AccountProvider {
    /**
     * 
     * @type {string}
     * @memberof AccountProvider
     */
    'minAmount': string;
    /**
     * 
     * @type {Array<AccountProviderRequisite>}
     * @memberof AccountProvider
     */
    'requisites': Array<AccountProviderRequisite>;
    /**
     * 
     * @type {number}
     * @memberof AccountProvider
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AccountProvider
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AccountProvider
     */
    'rule': string | null;
    /**
     * 
     * @type {TinyCurrency}
     * @memberof AccountProvider
     */
    'currency': TinyCurrency;
    /**
     * 
     * @type {TinyFee}
     * @memberof AccountProvider
     */
    'fee': TinyFee | null;
}
/**
 * 
 * @export
 * @interface AccountProviderRequisite
 */
export interface AccountProviderRequisite {
    /**
     * 
     * @type {string}
     * @memberof AccountProviderRequisite
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof AccountProviderRequisite
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface AuthProvider
 */
export interface AuthProvider {
    /**
     * тип провайдера
     * @type {string}
     * @memberof AuthProvider
     */
    'type': AuthProviderTypeEnum;
    /**
     * 
     * @type {AuthProviderData}
     * @memberof AuthProvider
     */
    'data': AuthProviderData;
    /**
     * id провайдера
     * @type {number}
     * @memberof AuthProvider
     */
    'id': number;
}

export const AuthProviderTypeEnum = {
    Email: 'email',
    Telegram: 'telegram',
    Google: 'google'
} as const;

export type AuthProviderTypeEnum = typeof AuthProviderTypeEnum[keyof typeof AuthProviderTypeEnum];

/**
 * данные провайдера
 * @export
 * @interface AuthProviderData
 */
export interface AuthProviderData {
    /**
     * почта
     * @type {string}
     * @memberof AuthProviderData
     */
    'email': string | null;
    /**
     * имя
     * @type {string}
     * @memberof AuthProviderData
     */
    'firstname': string | null;
    /**
     * фамилия
     * @type {string}
     * @memberof AuthProviderData
     */
    'lastname': string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthProviderData
     */
    'username'?: string;
    /**
     * ссылки на фото профиля
     * @type {string}
     * @memberof AuthProviderData
     */
    'photoUrl': string | null;
    /**
     * полное имя
     * @type {string}
     * @memberof AuthProviderData
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'imageUrl2x': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    'seoText': string | null;
}
/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * id бренда
     * @type {number}
     * @memberof Brand
     */
    'id': number;
    /**
     * названия бренда
     * @type {string}
     * @memberof Brand
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    'regionId': number;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'rate': string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'symbol': string;
}
/**
 * 
 * @export
 * @interface DaySchedule
 */
export interface DaySchedule {
    /**
     * числовой день недели, вс = 0
     * @type {number}
     * @memberof DaySchedule
     */
    'dayOfWeek': number;
    /**
     * время начала перида, 04:05
     * @type {string}
     * @memberof DaySchedule
     */
    'start': string;
    /**
     * время конца периода, 04:05
     * @type {string}
     * @memberof DaySchedule
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface District
 */
export interface District {
    /**
     * 
     * @type {number}
     * @memberof District
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof District
     */
    'cityId': number;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof District
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface EmailProvider
 */
export interface EmailProvider {
    /**
     * 
     * @type {string}
     * @memberof EmailProvider
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface EmailProviderCreateInput
 */
export interface EmailProviderCreateInput {
    /**
     * 
     * @type {string}
     * @memberof EmailProviderCreateInput
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailProviderCreateInput
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EmailSignInInput
 */
export interface EmailSignInInput {
    /**
     * пароль
     * @type {string}
     * @memberof EmailSignInInput
     */
    'password': string;
    /**
     * user email
     * @type {string}
     * @memberof EmailSignInInput
     */
    'login': string;
}
/**
 * 
 * @export
 * @interface EmailSignUpInput
 */
export interface EmailSignUpInput {
    /**
     * Роль
     * @type {string}
     * @memberof EmailSignUpInput
     */
    'role': EmailSignUpInputRoleEnum;
    /**
     * язык пользователя
     * @type {string}
     * @memberof EmailSignUpInput
     */
    'language': EmailSignUpInputLanguageEnum;
    /**
     * user email
     * @type {string}
     * @memberof EmailSignUpInput
     */
    'login': string;
    /**
     * ник
     * @type {string}
     * @memberof EmailSignUpInput
     */
    'nickname': string;
    /**
     * id страны, к которой относится рекламодатель
     * @type {number}
     * @memberof EmailSignUpInput
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpInput
     */
    'timezone'?: string;
}

export const EmailSignUpInputRoleEnum = {
    Customer: 'customer',
    User: 'user'
} as const;

export type EmailSignUpInputRoleEnum = typeof EmailSignUpInputRoleEnum[keyof typeof EmailSignUpInputRoleEnum];
export const EmailSignUpInputLanguageEnum = {
    En: 'en',
    Ru: 'ru'
} as const;

export type EmailSignUpInputLanguageEnum = typeof EmailSignUpInputLanguageEnum[keyof typeof EmailSignUpInputLanguageEnum];

/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {Array<FeatureValue>}
     * @memberof Feature
     */
    'values': Array<FeatureValue>;
    /**
     * 
     * @type {number}
     * @memberof Feature
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    'code': string | null;
}
/**
 * 
 * @export
 * @interface FeatureValue
 */
export interface FeatureValue {
    /**
     * 
     * @type {number}
     * @memberof FeatureValue
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureValue
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureValue
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface FriendPerson
 */
export interface FriendPerson {
    /**
     * 
     * @type {number}
     * @memberof FriendPerson
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof FriendPerson
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FriendRequestUpdate
 */
export interface FriendRequestUpdate {
    /**
     * статус запроса в подружки
     * @type {string}
     * @memberof FriendRequestUpdate
     */
    'status': FriendRequestUpdateStatusEnum;
}

export const FriendRequestUpdateStatusEnum = {
    Sent: 'sent',
    Approved: 'approved',
    Rejected: 'rejected',
    Canceled: 'canceled'
} as const;

export type FriendRequestUpdateStatusEnum = typeof FriendRequestUpdateStatusEnum[keyof typeof FriendRequestUpdateStatusEnum];

/**
 * 
 * @export
 * @interface FriendshipRequest
 */
export interface FriendshipRequest {
    /**
     * анкета подружки
     * @type {FriendPerson}
     * @memberof FriendshipRequest
     */
    'person': FriendPerson;
    /**
     * статус заявки
     * @type {object}
     * @memberof FriendshipRequest
     */
    'status': object;
}
/**
 * 
 * @export
 * @interface FriendshipRequestCreate
 */
export interface FriendshipRequestCreate {
    /**
     * id анкеты
     * @type {number}
     * @memberof FriendshipRequestCreate
     */
    'personId': number;
}
/**
 * 
 * @export
 * @interface GoogleProvider
 */
export interface GoogleProvider {
    /**
     * почта
     * @type {string}
     * @memberof GoogleProvider
     */
    'email': string | null;
    /**
     * полное имя
     * @type {string}
     * @memberof GoogleProvider
     */
    'name': string | null;
    /**
     * ссылки на фото профиля
     * @type {string}
     * @memberof GoogleProvider
     */
    'photoUrl': string | null;
    /**
     * имя
     * @type {string}
     * @memberof GoogleProvider
     */
    'firstname': string | null;
    /**
     * фамилия
     * @type {string}
     * @memberof GoogleProvider
     */
    'lastname': string | null;
}
/**
 * 
 * @export
 * @interface GoogleProviderCreateInput
 */
export interface GoogleProviderCreateInput {
    /**
     * токен входа google @description https://developers.google.com/identity/sign-in/web/backend-auth?hl=ru#send-the-id-token-to-your-server
     * @type {string}
     * @memberof GoogleProviderCreateInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface GoogleSignInInput
 */
export interface GoogleSignInInput {
    /**
     * токен входа google @description https://developers.google.com/identity/sign-in/web/backend-auth?hl=ru#send-the-id-token-to-your-server
     * @type {string}
     * @memberof GoogleSignInInput
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface GoogleSignUpInput
 */
export interface GoogleSignUpInput {
    /**
     * Роль
     * @type {string}
     * @memberof GoogleSignUpInput
     */
    'role': GoogleSignUpInputRoleEnum;
    /**
     * язык пользователя
     * @type {string}
     * @memberof GoogleSignUpInput
     */
    'language': GoogleSignUpInputLanguageEnum;
    /**
     * токен входа google @description https://developers.google.com/identity/sign-in/web/backend-auth?hl=ru#send-the-id-token-to-your-server
     * @type {string}
     * @memberof GoogleSignUpInput
     */
    'token': string;
    /**
     * ник
     * @type {string}
     * @memberof GoogleSignUpInput
     */
    'nickname': string;
    /**
     * id страны, к которой относится рекламодатель
     * @type {number}
     * @memberof GoogleSignUpInput
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleSignUpInput
     */
    'timezone'?: string;
}

export const GoogleSignUpInputRoleEnum = {
    Customer: 'customer',
    User: 'user'
} as const;

export type GoogleSignUpInputRoleEnum = typeof GoogleSignUpInputRoleEnum[keyof typeof GoogleSignUpInputRoleEnum];
export const GoogleSignUpInputLanguageEnum = {
    En: 'en',
    Ru: 'ru'
} as const;

export type GoogleSignUpInputLanguageEnum = typeof GoogleSignUpInputLanguageEnum[keyof typeof GoogleSignUpInputLanguageEnum];

/**
 * 
 * @export
 * @interface I18nTranslation
 */
export interface I18nTranslation {
    /**
     * перевод
     * @type {string}
     * @memberof I18nTranslation
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'genitive': string | null;
    /**
     * 
     * @type {string}
     * @memberof I18nTranslation
     */
    'prepositional': string | null;
}
/**
 * 
 * @export
 * @interface MenuCity
 */
export interface MenuCity {
    /**
     * 
     * @type {number}
     * @memberof MenuCity
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MenuCity
     */
    'regionId': number;
    /**
     * 
     * @type {string}
     * @memberof MenuCity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MenuCity
     */
    'slug': string;
    /**
     * 
     * @type {Array<District>}
     * @memberof MenuCity
     */
    'districts': Array<District>;
}
/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'keywords': string;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'seoText': string | null;
    /**
     * 
     * @type {string}
     * @memberof Meta
     */
    'seoTextMain': string | null;
}
/**
 * 
 * @export
 * @interface MetroStation
 */
export interface MetroStation {
    /**
     * 
     * @type {number}
     * @memberof MetroStation
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MetroStation
     */
    'cityId': number;
    /**
     * 
     * @type {string}
     * @memberof MetroStation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MetroStation
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * тело уведомления
     * @type {NotificationContext}
     * @memberof Notification
     */
    'body': NotificationContext;
    /**
     * статус уведомления
     * @type {string}
     * @memberof Notification
     */
    'status': NotificationStatusEnum;
    /**
     * id message
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * Дата уведомления
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
}

export const NotificationStatusEnum = {
    Readed: 'readed',
    NotReaded: 'not_readed',
    Deleted: 'deleted'
} as const;

export type NotificationStatusEnum = typeof NotificationStatusEnum[keyof typeof NotificationStatusEnum];

/**
 * 
 * @export
 * @interface NotificationContext
 */
export interface NotificationContext {
    /**
     * заголовок уведомления
     * @type {string}
     * @memberof NotificationContext
     */
    'title': string;
    /**
     * текст уведомления
     * @type {string}
     * @memberof NotificationContext
     */
    'message': string;
    /**
     * ссылка на картинку
     * @type {string}
     * @memberof NotificationContext
     */
    'image'?: string;
    /**
     * ссылка в уведомлении
     * @type {string}
     * @memberof NotificationContext
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface NotificationControllerFindAll200Response
 */
export interface NotificationControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof NotificationControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationControllerFindAll200Response
     */
    'items': Array<Notification>;
}
/**
 * 
 * @export
 * @interface PaginatedResponse
 */
export interface PaginatedResponse {
    /**
     * Total elements count
     * @type {number}
     * @memberof PaginatedResponse
     */
    'total': number;
    /**
     * Elements in response
     * @type {Array<Array<string>>}
     * @memberof PaginatedResponse
     */
    'items': Array<Array<string>>;
}
/**
 * 
 * @export
 * @interface PasswordResetInput
 */
export interface PasswordResetInput {
    /**
     * user email
     * @type {string}
     * @memberof PasswordResetInput
     */
    'login': string;
}
/**
 * 
 * @export
 * @interface PasswordResetVerifyInput
 */
export interface PasswordResetVerifyInput {
    /**
     * токен смены пароля
     * @type {string}
     * @memberof PasswordResetVerifyInput
     */
    'token': string;
    /**
     * новый пароль
     * @type {string}
     * @memberof PasswordResetVerifyInput
     */
    'password': string;
    /**
     * user email
     * @type {string}
     * @memberof PasswordResetVerifyInput
     */
    'login': string;
}
/**
 * 
 * @export
 * @interface PersonComment
 */
export interface PersonComment {
    /**
     * id комментария
     * @type {number}
     * @memberof PersonComment
     */
    'id': number;
    /**
     * кто написал комментарий
     * @type {PersonCommentUser}
     * @memberof PersonComment
     */
    'user': PersonCommentUser;
    /**
     * комментарий
     * @type {string}
     * @memberof PersonComment
     */
    'comment': string;
    /**
     * дата добавления
     * @type {string}
     * @memberof PersonComment
     */
    'createdAt': string;
    /**
     * ответы на комментарий
     * @type {Array<PersonComment>}
     * @memberof PersonComment
     */
    'children'?: Array<PersonComment>;
}
/**
 * 
 * @export
 * @interface PersonCommentControllerFindAllPersonComments200Response
 */
export interface PersonCommentControllerFindAllPersonComments200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof PersonCommentControllerFindAllPersonComments200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<PersonComment>}
     * @memberof PersonCommentControllerFindAllPersonComments200Response
     */
    'items': Array<PersonComment>;
}
/**
 * 
 * @export
 * @interface PersonCommentCreate
 */
export interface PersonCommentCreate {
    /**
     * комментарий к салону
     * @type {string}
     * @memberof PersonCommentCreate
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface PersonCommentUser
 */
export interface PersonCommentUser {
    /**
     * user nickname
     * @type {string}
     * @memberof PersonCommentUser
     */
    'nickname': string;
}
/**
 * 
 * @export
 * @interface PersonContacts
 */
export interface PersonContacts {
    /**
     * номер телефона
     * @type {string}
     * @memberof PersonContacts
     */
    'phone': string | null;
    /**
     * аккаунт в телеграм
     * @type {string}
     * @memberof PersonContacts
     */
    'telegram': string | null;
    /**
     * whatsapp
     * @type {string}
     * @memberof PersonContacts
     */
    'whatsapp': string | null;
}
/**
 * 
 * @export
 * @interface PersonControllerFindAll200Response
 */
export interface PersonControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof PersonControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<PublicPersonDtoItem>}
     * @memberof PersonControllerFindAll200Response
     */
    'items': Array<PublicPersonDtoItem>;
}
/**
 * 
 * @export
 * @interface PersonControllerFindFriendsById200Response
 */
export interface PersonControllerFindFriendsById200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof PersonControllerFindFriendsById200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<PublicPersonDto>}
     * @memberof PersonControllerFindFriendsById200Response
     */
    'items': Array<PublicPersonDto>;
}
/**
 * 
 * @export
 * @interface PersonCreateDto
 */
export interface PersonCreateDto {
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'slug': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'lon': number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'catalogCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'cityId': number;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'metroStationId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonCreateDto
     */
    'salonId': number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'excerpt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonCreateDto
     */
    'hasExpress': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'expressInfo': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonCreateDto
     */
    'showPrepayment': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonCreateDto
     */
    'mediaIds': Array<number>;
    /**
     * 
     * @type {Array<PersonFeature>}
     * @memberof PersonCreateDto
     */
    'features': Array<PersonFeature>;
    /**
     * 
     * @type {string}
     * @memberof PersonCreateDto
     */
    'phone': string;
    /**
     * аккаунт в телеграм
     * @type {string}
     * @memberof PersonCreateDto
     */
    'telegram': string | null;
    /**
     * whatsapp
     * @type {string}
     * @memberof PersonCreateDto
     */
    'whatsapp': string | null;
    /**
     * id видео верификации
     * @type {number}
     * @memberof PersonCreateDto
     */
    'verificationVideoId': number;
    /**
     * id кода верификации
     * @type {number}
     * @memberof PersonCreateDto
     */
    'verificationCodeId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonCreateDto
     */
    'brandIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonCreateDto
     */
    'telegramChannelIds': Array<number>;
}
/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'status': PersonDtoStatusEnum;
    /**
     * статус опубликованности анкеты
     * @type {string}
     * @memberof PersonDto
     */
    'publishStatus': PersonDtoPublishStatusEnum;
    /**
     * 
     * @type {Array<TinyBrand>}
     * @memberof PersonDto
     */
    'brands': Array<TinyBrand>;
    /**
     * 
     * @type {Array<TinyTelegramChannel>}
     * @memberof PersonDto
     */
    'telegramChannels': Array<TinyTelegramChannel>;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'slug': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'catalogCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'cityId': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'metroStationId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'salonId': number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'excerpt': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'lon': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDto
     */
    'showPrepayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PersonDto
     */
    'hasExpress': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'expressInfo': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'hits': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'likes': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'interests': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'topPlace': number;
    /**
     * 
     * @type {number}
     * @memberof PersonDto
     */
    'top': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof PersonDto
     */
    'media': Array<object>;
    /**
     * включен ли планировщик расписания для анкеты
     * @type {boolean}
     * @memberof PersonDto
     */
    'isScheduleEnabled': boolean;
    /**
     * Health+ статус
     * @type {TinyHealthStatus}
     * @memberof PersonDto
     */
    'healthStatus': TinyHealthStatus | null;
}

export const PersonDtoStatusEnum = {
    Active: 'active',
    Banned: 'banned',
    Rereview: 'rereview',
    Archived: 'archived'
} as const;

export type PersonDtoStatusEnum = typeof PersonDtoStatusEnum[keyof typeof PersonDtoStatusEnum];
export const PersonDtoPublishStatusEnum = {
    Published: 'published',
    Unpublished: 'unpublished'
} as const;

export type PersonDtoPublishStatusEnum = typeof PersonDtoPublishStatusEnum[keyof typeof PersonDtoPublishStatusEnum];

/**
 * 
 * @export
 * @interface PersonFeature
 */
export interface PersonFeature {
    /**
     * id значения фичи
     * @type {number}
     * @memberof PersonFeature
     */
    'featureValueId': number;
    /**
     * дополнительный комментарий
     * @type {string}
     * @memberof PersonFeature
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface PersonMarks
 */
export interface PersonMarks {
    /**
     * есть ли видео у анкеты
     * @type {boolean}
     * @memberof PersonMarks
     */
    'video': boolean;
    /**
     * число комментариев ходоков
     * @type {number}
     * @memberof PersonMarks
     */
    'comments': number;
}
/**
 * 
 * @export
 * @interface PersonReviewComment
 */
export interface PersonReviewComment {
    /**
     * текст комментария
     * @type {string}
     * @memberof PersonReviewComment
     */
    'text': string;
    /**
     * дата комментария
     * @type {string}
     * @memberof PersonReviewComment
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface PersonReviewControllerFindAll200Response
 */
export interface PersonReviewControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof PersonReviewControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<ReviewRequest>}
     * @memberof PersonReviewControllerFindAll200Response
     */
    'items': Array<ReviewRequest>;
}
/**
 * 
 * @export
 * @interface PersonUpdateDto
 */
export interface PersonUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'descriptionEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'nameEn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'slug'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'lat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'lon'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'catalogCategoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'cityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'districtId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'metroStationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'salonId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'excerpt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonUpdateDto
     */
    'hasExpress'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'expressInfo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonUpdateDto
     */
    'showPrepayment'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonUpdateDto
     */
    'mediaIds'?: Array<number>;
    /**
     * 
     * @type {Array<PersonFeature>}
     * @memberof PersonUpdateDto
     */
    'features'?: Array<PersonFeature>;
    /**
     * 
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'phone'?: string;
    /**
     * аккаунт в телеграм
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'telegram'?: string | null;
    /**
     * whatsapp
     * @type {string}
     * @memberof PersonUpdateDto
     */
    'whatsapp'?: string | null;
    /**
     * id видео верификации
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'verificationVideoId'?: number;
    /**
     * id кода верификации
     * @type {number}
     * @memberof PersonUpdateDto
     */
    'verificationCodeId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonUpdateDto
     */
    'brandIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonUpdateDto
     */
    'telegramChannelIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * язык
     * @type {string}
     * @memberof Profile
     */
    'language': ProfileLanguageEnum;
    /**
     * страна (только для рекламодателей)
     * @type {TinyCountry}
     * @memberof Profile
     */
    'country': TinyCountry | null;
    /**
     * id пользователя
     * @type {number}
     * @memberof Profile
     */
    'id': number;
    /**
     * ник
     * @type {string}
     * @memberof Profile
     */
    'nickname': string;
    /**
     * таймзона
     * @type {string}
     * @memberof Profile
     */
    'timezone': string;
    /**
     * баланс пользователя
     * @type {object}
     * @memberof Profile
     */
    'balance': object;
}

export const ProfileLanguageEnum = {
    En: 'en',
    Ru: 'ru'
} as const;

export type ProfileLanguageEnum = typeof ProfileLanguageEnum[keyof typeof ProfileLanguageEnum];

/**
 * 
 * @export
 * @interface ProfileUpdateInput
 */
export interface ProfileUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateInput
     */
    'language'?: ProfileUpdateInputLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateInput
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileUpdateInput
     */
    'timezone'?: string;
}

export const ProfileUpdateInputLanguageEnum = {
    En: 'en',
    Ru: 'ru'
} as const;

export type ProfileUpdateInputLanguageEnum = typeof ProfileUpdateInputLanguageEnum[keyof typeof ProfileUpdateInputLanguageEnum];

/**
 * 
 * @export
 * @interface PublicMedia
 */
export interface PublicMedia {
    /**
     * 
     * @type {string}
     * @memberof PublicMedia
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof PublicMedia
     */
    'mimetype': string;
    /**
     * 
     * @type {string}
     * @memberof PublicMedia
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof PublicMedia
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PublicPersonDto
 */
export interface PublicPersonDto {
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'slug': string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'catalogCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'cityId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'metroStationId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'salonId': number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'excerpt': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDto
     */
    'lon': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPersonDto
     */
    'showPrepayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPersonDto
     */
    'hasExpress': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDto
     */
    'expressInfo': string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof PublicPersonDto
     */
    'media': Array<object>;
    /**
     * 
     * @type {TinyHealthStatus}
     * @memberof PublicPersonDto
     */
    'healthStatus': TinyHealthStatus | null;
}
/**
 * 
 * @export
 * @interface PublicPersonDtoItem
 */
export interface PublicPersonDtoItem {
    /**
     * 
     * @type {PersonMarks}
     * @memberof PublicPersonDtoItem
     */
    'marks': PersonMarks;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'slug': string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'catalogCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'cityId': number;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'metroStationId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'salonId': number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'excerpt': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicPersonDtoItem
     */
    'lon': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPersonDtoItem
     */
    'showPrepayment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicPersonDtoItem
     */
    'hasExpress': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PublicPersonDtoItem
     */
    'expressInfo': string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof PublicPersonDtoItem
     */
    'media': Array<object>;
    /**
     * 
     * @type {TinyHealthStatus}
     * @memberof PublicPersonDtoItem
     */
    'healthStatus': TinyHealthStatus | null;
}
/**
 * 
 * @export
 * @interface PublicSalonDto
 */
export interface PublicSalonDto {
    /**
     * 
     * @type {number}
     * @memberof PublicSalonDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'prices': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicSalonDto
     */
    'workingHours': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PublicSalonDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicSalonDto
     */
    'lon': number | null;
    /**
     * 
     * @type {TinyDistrict}
     * @memberof PublicSalonDto
     */
    'district': TinyDistrict | null;
    /**
     * 
     * @type {TinyCity}
     * @memberof PublicSalonDto
     */
    'city': TinyCity;
    /**
     * 
     * @type {number}
     * @memberof PublicSalonDto
     */
    'userId': number;
    /**
     * 
     * @type {Array<object>}
     * @memberof PublicSalonDto
     */
    'media': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PublicSalonDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface PublishUpdateInput
 */
export interface PublishUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof PublishUpdateInput
     */
    'status': PublishUpdateInputStatusEnum;
}

export const PublishUpdateInputStatusEnum = {
    Published: 'published',
    Unpublished: 'unpublished'
} as const;

export type PublishUpdateInputStatusEnum = typeof PublishUpdateInputStatusEnum[keyof typeof PublishUpdateInputStatusEnum];

/**
 * 
 * @export
 * @interface PublishingStatus
 */
export interface PublishingStatus {
    /**
     * текущий статус показа анкеты
     * @type {string}
     * @memberof PublishingStatus
     */
    'publishStatus': PublishingStatusPublishStatusEnum;
}

export const PublishingStatusPublishStatusEnum = {
    Published: 'published',
    Unpublished: 'unpublished'
} as const;

export type PublishingStatusPublishStatusEnum = typeof PublishingStatusPublishStatusEnum[keyof typeof PublishingStatusPublishStatusEnum];

/**
 * 
 * @export
 * @interface RateResponse
 */
export interface RateResponse {
    /**
     * сконвертированное кол-во
     * @type {object}
     * @memberof RateResponse
     */
    'amount': object;
}
/**
 * 
 * @export
 * @interface Redirect
 */
export interface Redirect {
    /**
     * 
     * @type {string}
     * @memberof Redirect
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof Redirect
     */
    'to': string;
    /**
     * 
     * @type {number}
     * @memberof Redirect
     */
    'httpCode': number;
}
/**
 * 
 * @export
 * @interface RefreshInput
 */
export interface RefreshInput {
    /**
     * 
     * @type {string}
     * @memberof RefreshInput
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    'countryId': number;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ReplenishTransaction
 */
export interface ReplenishTransaction {
    /**
     * статус пополнения
     * @type {string}
     * @memberof ReplenishTransaction
     */
    'status': ReplenishTransactionStatusEnum;
    /**
     * id транзакции пополнения
     * @type {number}
     * @memberof ReplenishTransaction
     */
    'id': number;
    /**
     * платежный провайдер
     * @type {TinyAccountProvider}
     * @memberof ReplenishTransaction
     */
    'accountProvider': TinyAccountProvider;
    /**
     * пополнение в валюте провайдера
     * @type {object}
     * @memberof ReplenishTransaction
     */
    'amount': object;
    /**
     * пополнение в USD без учета комисии
     * @type {object}
     * @memberof ReplenishTransaction
     */
    'amountInUSDWithoutFee': object;
    /**
     * пополнение в USD с учетом комиссии
     * @type {object}
     * @memberof ReplenishTransaction
     */
    'amountInUSD': object;
    /**
     * комментарий пользователя
     * @type {string}
     * @memberof ReplenishTransaction
     */
    'userComment': string | null;
    /**
     * комментарий администратора
     * @type {string}
     * @memberof ReplenishTransaction
     */
    'rejectReason': string | null;
}

export const ReplenishTransactionStatusEnum = {
    New: 'new',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ReplenishTransactionStatusEnum = typeof ReplenishTransactionStatusEnum[keyof typeof ReplenishTransactionStatusEnum];

/**
 * 
 * @export
 * @interface RestBalanceTime
 */
export interface RestBalanceTime {
    /**
     * дата, когда закончатся средства на счету (c учетом выключения анкеты)
     * @type {string}
     * @memberof RestBalanceTime
     */
    'end': string | null;
    /**
     * на сколько часов чистого показа анкеты хватит баланса средств
     * @type {number}
     * @memberof RestBalanceTime
     */
    'publishHours': number;
    /**
     * баланс не будет меняться, т.к. нет списаний
     * @type {boolean}
     * @memberof RestBalanceTime
     */
    'isInfinity': boolean;
}
/**
 * 
 * @export
 * @interface ReviewRequest
 */
export interface ReviewRequest {
    /**
     * статус обработки запроса на изменение анкеты
     * @type {string}
     * @memberof ReviewRequest
     */
    'status': ReviewRequestStatusEnum;
    /**
     * id запроса на изменение
     * @type {number}
     * @memberof ReviewRequest
     */
    'id': number;
    /**
     * изменение анкеты
     * @type {object}
     * @memberof ReviewRequest
     */
    'change': object;
    /**
     * анкета, к которой относится запрос (`null` для новых анкет)
     * @type {TinyPerson}
     * @memberof ReviewRequest
     */
    'person': TinyPerson | null;
    /**
     * 
     * @type {Array<PersonReviewComment>}
     * @memberof ReviewRequest
     */
    'comments': Array<PersonReviewComment>;
}

export const ReviewRequestStatusEnum = {
    New: 'new',
    InProgress: 'in_progress',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ReviewRequestStatusEnum = typeof ReviewRequestStatusEnum[keyof typeof ReviewRequestStatusEnum];

/**
 * 
 * @export
 * @interface SalonComment
 */
export interface SalonComment {
    /**
     * id комментария
     * @type {number}
     * @memberof SalonComment
     */
    'id': number;
    /**
     * кто написал комментарий
     * @type {SalonCommentUser}
     * @memberof SalonComment
     */
    'user': SalonCommentUser;
    /**
     * комментарий
     * @type {string}
     * @memberof SalonComment
     */
    'comment': string;
    /**
     * дата добавления
     * @type {string}
     * @memberof SalonComment
     */
    'createdAt': string;
    /**
     * ответы на комментарий
     * @type {Array<SalonComment>}
     * @memberof SalonComment
     */
    'children'?: Array<SalonComment>;
}
/**
 * 
 * @export
 * @interface SalonCommentControllerFindAll200Response
 */
export interface SalonCommentControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof SalonCommentControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<SalonComment>}
     * @memberof SalonCommentControllerFindAll200Response
     */
    'items': Array<SalonComment>;
}
/**
 * 
 * @export
 * @interface SalonCommentCreate
 */
export interface SalonCommentCreate {
    /**
     * комментарий к салону
     * @type {string}
     * @memberof SalonCommentCreate
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface SalonCommentUser
 */
export interface SalonCommentUser {
    /**
     * user nickname
     * @type {string}
     * @memberof SalonCommentUser
     */
    'nickname': string;
}
/**
 * 
 * @export
 * @interface SalonContacts
 */
export interface SalonContacts {
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonContacts
     */
    'phones': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SalonContacts
     */
    'telegram': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonContacts
     */
    'whatsapp': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonContacts
     */
    'website': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonContacts
     */
    'email': string | null;
}
/**
 * 
 * @export
 * @interface SalonControllerFindAll200Response
 */
export interface SalonControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof SalonControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<PublicSalonDto>}
     * @memberof SalonControllerFindAll200Response
     */
    'items': Array<PublicSalonDto>;
}
/**
 * 
 * @export
 * @interface SalonCreateDto
 */
export interface SalonCreateDto {
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'email': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonCreateDto
     */
    'workingHours': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SalonCreateDto
     */
    'isHidden': boolean;
    /**
     * 
     * @type {number}
     * @memberof SalonCreateDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonCreateDto
     */
    'lon': number | null;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'prices': string;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonCreateDto
     */
    'phones': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'whatsapp': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'telegram': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonCreateDto
     */
    'website': string | null;
    /**
     * 
     * @type {number}
     * @memberof SalonCreateDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonCreateDto
     */
    'cityId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonCreateDto
     */
    'mediaIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonCreateDto
     */
    'metroStationIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonCreateDto
     */
    'brandIds': Array<number>;
}
/**
 * 
 * @export
 * @interface SalonDto
 */
export interface SalonDto {
    /**
     * 
     * @type {Array<TinyMetroStation>}
     * @memberof SalonDto
     */
    'metroStations': Array<TinyMetroStation>;
    /**
     * 
     * @type {Array<TinyBrand>}
     * @memberof SalonDto
     */
    'brands': Array<TinyBrand>;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'prices': string;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'telegram': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'whatsapp': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'website': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonDto
     */
    'email': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonDto
     */
    'workingHours': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SalonDto
     */
    'isHidden': boolean;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'lon': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'cityId': number;
    /**
     * 
     * @type {number}
     * @memberof SalonDto
     */
    'userId': number;
    /**
     * список объектов медиа файлов
     * @type {Array<object>}
     * @memberof SalonDto
     */
    'media': Array<object>;
}
/**
 * 
 * @export
 * @interface SalonUpdateDto
 */
export interface SalonUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'nameEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'descriptionEn': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'email': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonUpdateDto
     */
    'workingHours': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SalonUpdateDto
     */
    'isHidden': boolean;
    /**
     * 
     * @type {number}
     * @memberof SalonUpdateDto
     */
    'lat': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonUpdateDto
     */
    'lon': number | null;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'prices': string;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalonUpdateDto
     */
    'phones': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'whatsapp': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'telegram': string | null;
    /**
     * 
     * @type {string}
     * @memberof SalonUpdateDto
     */
    'website': string | null;
    /**
     * 
     * @type {number}
     * @memberof SalonUpdateDto
     */
    'districtId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SalonUpdateDto
     */
    'cityId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonUpdateDto
     */
    'mediaIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonUpdateDto
     */
    'metroStationIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SalonUpdateDto
     */
    'brandIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * расписание показов
     * @type {Array<DaySchedule>}
     * @memberof Schedule
     */
    'schedules': Array<DaySchedule>;
    /**
     * статус расписания показов
     * @type {boolean}
     * @memberof Schedule
     */
    'isScheduleEnabled': boolean;
}
/**
 * 
 * @export
 * @interface ScheduleUpdate
 */
export interface ScheduleUpdate {
    /**
     * расписание показов
     * @type {Array<DaySchedule>}
     * @memberof ScheduleUpdate
     */
    'schedules': Array<DaySchedule>;
    /**
     * новый статус расписания показов
     * @type {boolean}
     * @memberof ScheduleUpdate
     */
    'isScheduleEnabled': boolean;
}
/**
 * 
 * @export
 * @interface StaticPage
 */
export interface StaticPage {
    /**
     * 
     * @type {string}
     * @memberof StaticPage
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof StaticPage
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StaticPage
     */
    'html': string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'isPartner': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    'isStarred': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tag
     */
    'excluded': Array<number>;
}
/**
 * 
 * @export
 * @interface TelegramChannel
 */
export interface TelegramChannel {
    /**
     * 
     * @type {number}
     * @memberof TelegramChannel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TelegramChannel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TelegramChannel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TelegramChannel
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface TelegramProvider
 */
export interface TelegramProvider {
    /**
     * 
     * @type {string}
     * @memberof TelegramProvider
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProvider
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProvider
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProvider
     */
    'photoUrl'?: string;
}
/**
 * 
 * @export
 * @interface TelegramProviderCreateInput
 */
export interface TelegramProviderCreateInput {
    /**
     * 
     * @type {number}
     * @memberof TelegramProviderCreateInput
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TelegramProviderCreateInput
     */
    'authDate': number;
    /**
     * 
     * @type {string}
     * @memberof TelegramProviderCreateInput
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProviderCreateInput
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProviderCreateInput
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProviderCreateInput
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramProviderCreateInput
     */
    'photoUrl'?: string;
}
/**
 * 
 * @export
 * @interface TelegramSignInInput
 */
export interface TelegramSignInInput {
    /**
     * 
     * @type {number}
     * @memberof TelegramSignInInput
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TelegramSignInInput
     */
    'authDate': number;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignInInput
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignInInput
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignInInput
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignInInput
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignInInput
     */
    'photoUrl'?: string;
}
/**
 * 
 * @export
 * @interface TelegramSignUpInput
 */
export interface TelegramSignUpInput {
    /**
     * Роль
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'role': TelegramSignUpInputRoleEnum;
    /**
     * язык пользователя
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'language': TelegramSignUpInputLanguageEnum;
    /**
     * 
     * @type {number}
     * @memberof TelegramSignUpInput
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'photoUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof TelegramSignUpInput
     */
    'authDate': number;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'hash': string;
    /**
     * ник
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'nickname': string;
    /**
     * id страны, к которой относится рекламодатель
     * @type {number}
     * @memberof TelegramSignUpInput
     */
    'countryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TelegramSignUpInput
     */
    'timezone'?: string;
}

export const TelegramSignUpInputRoleEnum = {
    Customer: 'customer',
    User: 'user'
} as const;

export type TelegramSignUpInputRoleEnum = typeof TelegramSignUpInputRoleEnum[keyof typeof TelegramSignUpInputRoleEnum];
export const TelegramSignUpInputLanguageEnum = {
    En: 'en',
    Ru: 'ru'
} as const;

export type TelegramSignUpInputLanguageEnum = typeof TelegramSignUpInputLanguageEnum[keyof typeof TelegramSignUpInputLanguageEnum];

/**
 * 
 * @export
 * @interface TinyAccountProvider
 */
export interface TinyAccountProvider {
    /**
     * id платежного провайдера
     * @type {number}
     * @memberof TinyAccountProvider
     */
    'id': number;
    /**
     * название платежного провайдера
     * @type {string}
     * @memberof TinyAccountProvider
     */
    'name': string;
    /**
     * символ платежного провайдера
     * @type {TinyCurrency}
     * @memberof TinyAccountProvider
     */
    'currency': TinyCurrency;
    /**
     * комиссия
     * @type {TinyFee}
     * @memberof TinyAccountProvider
     */
    'fee': TinyFee | null;
}
/**
 * 
 * @export
 * @interface TinyBrand
 */
export interface TinyBrand {
    /**
     * id бренда
     * @type {number}
     * @memberof TinyBrand
     */
    'id': number;
    /**
     * название бренда
     * @type {string}
     * @memberof TinyBrand
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TinyCity
 */
export interface TinyCity {
    /**
     * id города
     * @type {number}
     * @memberof TinyCity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TinyCity
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface TinyCountry
 */
export interface TinyCountry {
    /**
     * 
     * @type {number}
     * @memberof TinyCountry
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface TinyCurrency
 */
export interface TinyCurrency {
    /**
     * 
     * @type {number}
     * @memberof TinyCurrency
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TinyCurrency
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TinyCurrency
     */
    'symbol': string | null;
    /**
     * 
     * @type {object}
     * @memberof TinyCurrency
     */
    'rate': object;
}
/**
 * 
 * @export
 * @interface TinyDistrict
 */
export interface TinyDistrict {
    /**
     * 
     * @type {number}
     * @memberof TinyDistrict
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TinyDistrict
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface TinyFee
 */
export interface TinyFee {
    /**
     * описание комиссии
     * @type {string}
     * @memberof TinyFee
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface TinyHealthStatus
 */
export interface TinyHealthStatus {
    /**
     * id обследования
     * @type {number}
     * @memberof TinyHealthStatus
     */
    'id': number;
    /**
     * статус Health+
     * @type {boolean}
     * @memberof TinyHealthStatus
     */
    'isHealthy': boolean;
    /**
     * 
     * @type {string}
     * @memberof TinyHealthStatus
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TinyMetroStation
 */
export interface TinyMetroStation {
    /**
     * 
     * @type {number}
     * @memberof TinyMetroStation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TinyMetroStation
     */
    'slug': string;
    /**
     * 
     * @type {number}
     * @memberof TinyMetroStation
     */
    'cityId': number;
}
/**
 * 
 * @export
 * @interface TinyPerson
 */
export interface TinyPerson {
    /**
     * id анкеты
     * @type {number}
     * @memberof TinyPerson
     */
    'id': number;
    /**
     * название анкеты
     * @type {string}
     * @memberof TinyPerson
     */
    'name': string;
    /**
     * название анкеты на англ. языке
     * @type {string}
     * @memberof TinyPerson
     */
    'nameEn': string | null;
    /**
     * slug анкеты
     * @type {string}
     * @memberof TinyPerson
     */
    'slug': string | null;
}
/**
 * 
 * @export
 * @interface TinyTelegramChannel
 */
export interface TinyTelegramChannel {
    /**
     * 
     * @type {number}
     * @memberof TinyTelegramChannel
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TinyTelegramChannel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TinyTelegramChannel
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TinyTelegramChannel
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Tokens
 */
export interface Tokens {
    /**
     * access token
     * @type {string}
     * @memberof Tokens
     */
    'accessToken': string;
    /**
     * refresh token
     * @type {string}
     * @memberof Tokens
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * id транзакции
     * @type {number}
     * @memberof Transaction
     */
    'id': number;
    /**
     * тип сущности
     * @type {object}
     * @memberof Transaction
     */
    'entityType': object;
    /**
     * id сущности
     * @type {number}
     * @memberof Transaction
     */
    'entityId': number;
    /**
     * кол-во учтенных минут
     * @type {number}
     * @memberof Transaction
     */
    'accountedMinutes': number;
    /**
     * списанный баланс по данной транзакции
     * @type {object}
     * @memberof Transaction
     */
    'accountedBalance': object;
    /**
     * цена за минуту
     * @type {object}
     * @memberof Transaction
     */
    'price': object;
    /**
     * активна ли транзакция в данный момент
     * @type {boolean}
     * @memberof Transaction
     */
    'inProgress': boolean;
    /**
     * дата начала транзакции
     * @type {string}
     * @memberof Transaction
     */
    'start': string;
    /**
     * дата конца транзакции
     * @type {string}
     * @memberof Transaction
     */
    'end': string | null;
    /**
     * дата последнего обновления транзакции
     * @type {string}
     * @memberof Transaction
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpdateNotificationStatus
 */
export interface UpdateNotificationStatus {
    /**
     * статус нотификации
     * @type {string}
     * @memberof UpdateNotificationStatus
     */
    'status': UpdateNotificationStatusStatusEnum;
}

export const UpdateNotificationStatusStatusEnum = {
    Readed: 'readed',
    NotReaded: 'not_readed',
    Deleted: 'deleted'
} as const;

export type UpdateNotificationStatusStatusEnum = typeof UpdateNotificationStatusStatusEnum[keyof typeof UpdateNotificationStatusStatusEnum];

/**
 * 
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
    /**
     * id файла
     * @type {number}
     * @memberof UploadResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UserPersonControllerFindAll200Response
 */
export interface UserPersonControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof UserPersonControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<PersonDto>}
     * @memberof UserPersonControllerFindAll200Response
     */
    'items': Array<PersonDto>;
}
/**
 * 
 * @export
 * @interface UserSalonControllerFindAll200Response
 */
export interface UserSalonControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof UserSalonControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<SalonDto>}
     * @memberof UserSalonControllerFindAll200Response
     */
    'items': Array<SalonDto>;
}
/**
 * 
 * @export
 * @interface UserTransactionsControllerFindAll200Response
 */
export interface UserTransactionsControllerFindAll200Response {
    /**
     * Total elements count
     * @type {number}
     * @memberof UserTransactionsControllerFindAll200Response
     */
    'total': number;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof UserTransactionsControllerFindAll200Response
     */
    'items': Array<Transaction>;
}
/**
 * 
 * @export
 * @interface VerificationCode
 */
export interface VerificationCode {
    /**
     * id кода верификации
     * @type {number}
     * @memberof VerificationCode
     */
    'id': number;
    /**
     * код верификации
     * @type {string}
     * @memberof VerificationCode
     */
    'code': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Рефреш токенов в пользовательском приложении
         * @param {RefreshInput} refreshInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh: async (refreshInput: RefreshInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshInput' is not null or undefined
            assertParamExists('authControllerRefresh', 'refreshInput', refreshInput)
            const localVarPath = `/api/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление провайдера входа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authProviderControllerDelete', 'id', id)
            const localVarPath = `/api/v1/auth/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка привязанных провайдеров входа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderControllerFindAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление email провайдера входа
         * @param {EmailProviderCreateInput} emailProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerCreate: async (emailProviderCreateInput: EmailProviderCreateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailProviderCreateInput' is not null or undefined
            assertParamExists('emailControllerCreate', 'emailProviderCreateInput', emailProviderCreateInput)
            const localVarPath = `/api/v1/auth/providers/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailProviderCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка письма для сброса пароля
         * @param {PasswordResetInput} passwordResetInput 
         * @param {string} [xFrontendDomain] Выставляет заголовок x-frontend-domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerPasswordResetSendToken: async (passwordResetInput: PasswordResetInput, xFrontendDomain?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetInput' is not null or undefined
            assertParamExists('emailControllerPasswordResetSendToken', 'passwordResetInput', passwordResetInput)
            const localVarPath = `/api/v1/auth/providers/email/password-reset/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xFrontendDomain != null) {
                localVarHeaderParameter['x-frontend-domain'] = String(xFrontendDomain);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка токена сброса пароля и смена пароля
         * @param {PasswordResetVerifyInput} passwordResetVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerPasswordResetVerifyToken: async (passwordResetVerifyInput: PasswordResetVerifyInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetVerifyInput' is not null or undefined
            assertParamExists('emailControllerPasswordResetVerifyToken', 'passwordResetVerifyInput', passwordResetVerifyInput)
            const localVarPath = `/api/v1/auth/providers/email/password-reset/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetVerifyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин в пользовательском приложении
         * @param {EmailSignInInput} emailSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSignIn: async (emailSignInInput: EmailSignInInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSignInInput' is not null or undefined
            assertParamExists('emailControllerSignIn', 'emailSignInInput', emailSignInInput)
            const localVarPath = `/api/v1/auth/providers/email/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailSignInInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация в пользовательском приложении
         * @param {EmailSignUpInput} emailSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSignUp: async (emailSignUpInput: EmailSignUpInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailSignUpInput' is not null or undefined
            assertParamExists('emailControllerSignUp', 'emailSignUpInput', emailSignUpInput)
            const localVarPath = `/api/v1/auth/providers/email/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailSignUpInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление Google провайдера входа
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerCreate: async (googleProviderCreateInput: GoogleProviderCreateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleProviderCreateInput' is not null or undefined
            assertParamExists('googleControllerCreate', 'googleProviderCreateInput', googleProviderCreateInput)
            const localVarPath = `/api/v1/auth/providers/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleProviderCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновляет данные google аккаунта (после изменения прав доступа)
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerRefresh: async (googleProviderCreateInput: GoogleProviderCreateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleProviderCreateInput' is not null or undefined
            assertParamExists('googleControllerRefresh', 'googleProviderCreateInput', googleProviderCreateInput)
            const localVarPath = `/api/v1/auth/providers/google/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleProviderCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин через google
         * @param {GoogleSignInInput} googleSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerSignIn: async (googleSignInInput: GoogleSignInInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleSignInInput' is not null or undefined
            assertParamExists('googleControllerSignIn', 'googleSignInInput', googleSignInInput)
            const localVarPath = `/api/v1/auth/providers/google/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSignInInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин через google (с созданием пользователя)
         * @param {GoogleSignUpInput} googleSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerSignUp: async (googleSignUpInput: GoogleSignUpInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleSignUpInput' is not null or undefined
            assertParamExists('googleControllerSignUp', 'googleSignUpInput', googleSignUpInput)
            const localVarPath = `/api/v1/auth/providers/google/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSignUpInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление telegram провайдера входа
         * @param {TelegramProviderCreateInput} telegramProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerCreate: async (telegramProviderCreateInput: TelegramProviderCreateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telegramProviderCreateInput' is not null or undefined
            assertParamExists('telegramControllerCreate', 'telegramProviderCreateInput', telegramProviderCreateInput)
            const localVarPath = `/api/v1/auth/providers/telegram`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telegramProviderCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин через telegram
         * @param {TelegramSignInInput} telegramSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerSignIn: async (telegramSignInInput: TelegramSignInInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telegramSignInInput' is not null or undefined
            assertParamExists('telegramControllerSignIn', 'telegramSignInInput', telegramSignInInput)
            const localVarPath = `/api/v1/auth/providers/telegram/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telegramSignInInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Логин через telegram (с созданием пользователя)
         * @param {TelegramSignUpInput} telegramSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerSignUp: async (telegramSignUpInput: TelegramSignUpInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telegramSignUpInput' is not null or undefined
            assertParamExists('telegramControllerSignUp', 'telegramSignUpInput', telegramSignUpInput)
            const localVarPath = `/api/v1/auth/providers/telegram/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telegramSignUpInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Рефреш токенов в пользовательском приложении
         * @param {RefreshInput} refreshInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefresh(refreshInput: RefreshInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefresh(refreshInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Удаление провайдера входа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authProviderControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authProviderControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authProviderControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение списка привязанных провайдеров входа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authProviderControllerFindAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authProviderControllerFindAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authProviderControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление email провайдера входа
         * @param {EmailProviderCreateInput} emailProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerCreate(emailProviderCreateInput: EmailProviderCreateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerCreate(emailProviderCreateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.emailControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Отправка письма для сброса пароля
         * @param {PasswordResetInput} passwordResetInput 
         * @param {string} [xFrontendDomain] Выставляет заголовок x-frontend-domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerPasswordResetSendToken(passwordResetInput: PasswordResetInput, xFrontendDomain?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerPasswordResetSendToken(passwordResetInput, xFrontendDomain, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.emailControllerPasswordResetSendToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Проверка токена сброса пароля и смена пароля
         * @param {PasswordResetVerifyInput} passwordResetVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerPasswordResetVerifyToken(passwordResetVerifyInput: PasswordResetVerifyInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerPasswordResetVerifyToken(passwordResetVerifyInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.emailControllerPasswordResetVerifyToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Логин в пользовательском приложении
         * @param {EmailSignInInput} emailSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSignIn(emailSignInInput: EmailSignInInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSignIn(emailSignInInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.emailControllerSignIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Регистрация в пользовательском приложении
         * @param {EmailSignUpInput} emailSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailControllerSignUp(emailSignUpInput: EmailSignUpInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailControllerSignUp(emailSignUpInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.emailControllerSignUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление Google провайдера входа
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleControllerCreate(googleProviderCreateInput: GoogleProviderCreateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleControllerCreate(googleProviderCreateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.googleControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Обновляет данные google аккаунта (после изменения прав доступа)
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleControllerRefresh(googleProviderCreateInput: GoogleProviderCreateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleControllerRefresh(googleProviderCreateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.googleControllerRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Логин через google
         * @param {GoogleSignInInput} googleSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleControllerSignIn(googleSignInInput: GoogleSignInInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleControllerSignIn(googleSignInInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.googleControllerSignIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Логин через google (с созданием пользователя)
         * @param {GoogleSignUpInput} googleSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleControllerSignUp(googleSignUpInput: GoogleSignUpInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleControllerSignUp(googleSignUpInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.googleControllerSignUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление telegram провайдера входа
         * @param {TelegramProviderCreateInput} telegramProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async telegramControllerCreate(telegramProviderCreateInput: TelegramProviderCreateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelegramProvider>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.telegramControllerCreate(telegramProviderCreateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.telegramControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Логин через telegram
         * @param {TelegramSignInInput} telegramSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async telegramControllerSignIn(telegramSignInInput: TelegramSignInInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.telegramControllerSignIn(telegramSignInInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.telegramControllerSignIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Логин через telegram (с созданием пользователя)
         * @param {TelegramSignUpInput} telegramSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async telegramControllerSignUp(telegramSignUpInput: TelegramSignUpInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.telegramControllerSignUp(telegramSignUpInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.telegramControllerSignUp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Рефреш токенов в пользовательском приложении
         * @param {RefreshInput} refreshInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(refreshInput: RefreshInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.authControllerRefresh(refreshInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление провайдера входа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.authProviderControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка привязанных провайдеров входа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authProviderControllerFindAll(options?: any): AxiosPromise<Array<AuthProvider>> {
            return localVarFp.authProviderControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление email провайдера входа
         * @param {EmailProviderCreateInput} emailProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerCreate(emailProviderCreateInput: EmailProviderCreateInput, options?: any): AxiosPromise<EmailProvider> {
            return localVarFp.emailControllerCreate(emailProviderCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка письма для сброса пароля
         * @param {PasswordResetInput} passwordResetInput 
         * @param {string} [xFrontendDomain] Выставляет заголовок x-frontend-domain
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerPasswordResetSendToken(passwordResetInput: PasswordResetInput, xFrontendDomain?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailControllerPasswordResetSendToken(passwordResetInput, xFrontendDomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка токена сброса пароля и смена пароля
         * @param {PasswordResetVerifyInput} passwordResetVerifyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerPasswordResetVerifyToken(passwordResetVerifyInput: PasswordResetVerifyInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.emailControllerPasswordResetVerifyToken(passwordResetVerifyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Логин в пользовательском приложении
         * @param {EmailSignInInput} emailSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSignIn(emailSignInInput: EmailSignInInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.emailControllerSignIn(emailSignInInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация в пользовательском приложении
         * @param {EmailSignUpInput} emailSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailControllerSignUp(emailSignUpInput: EmailSignUpInput, options?: any): AxiosPromise<void> {
            return localVarFp.emailControllerSignUp(emailSignUpInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление Google провайдера входа
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerCreate(googleProviderCreateInput: GoogleProviderCreateInput, options?: any): AxiosPromise<GoogleProvider> {
            return localVarFp.googleControllerCreate(googleProviderCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновляет данные google аккаунта (после изменения прав доступа)
         * @param {GoogleProviderCreateInput} googleProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerRefresh(googleProviderCreateInput: GoogleProviderCreateInput, options?: any): AxiosPromise<void> {
            return localVarFp.googleControllerRefresh(googleProviderCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Логин через google
         * @param {GoogleSignInInput} googleSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerSignIn(googleSignInInput: GoogleSignInInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.googleControllerSignIn(googleSignInInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Логин через google (с созданием пользователя)
         * @param {GoogleSignUpInput} googleSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleControllerSignUp(googleSignUpInput: GoogleSignUpInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.googleControllerSignUp(googleSignUpInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление telegram провайдера входа
         * @param {TelegramProviderCreateInput} telegramProviderCreateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerCreate(telegramProviderCreateInput: TelegramProviderCreateInput, options?: any): AxiosPromise<TelegramProvider> {
            return localVarFp.telegramControllerCreate(telegramProviderCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Логин через telegram
         * @param {TelegramSignInInput} telegramSignInInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerSignIn(telegramSignInInput: TelegramSignInInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.telegramControllerSignIn(telegramSignInInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Логин через telegram (с созданием пользователя)
         * @param {TelegramSignUpInput} telegramSignUpInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        telegramControllerSignUp(telegramSignUpInput: TelegramSignUpInput, options?: any): AxiosPromise<Tokens> {
            return localVarFp.telegramControllerSignUp(telegramSignUpInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Рефреш токенов в пользовательском приложении
     * @param {RefreshInput} refreshInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRefresh(refreshInput: RefreshInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRefresh(refreshInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление провайдера входа
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authProviderControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authProviderControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка привязанных провайдеров входа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authProviderControllerFindAll(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authProviderControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление email провайдера входа
     * @param {EmailProviderCreateInput} emailProviderCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public emailControllerCreate(emailProviderCreateInput: EmailProviderCreateInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).emailControllerCreate(emailProviderCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка письма для сброса пароля
     * @param {PasswordResetInput} passwordResetInput 
     * @param {string} [xFrontendDomain] Выставляет заголовок x-frontend-domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public emailControllerPasswordResetSendToken(passwordResetInput: PasswordResetInput, xFrontendDomain?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).emailControllerPasswordResetSendToken(passwordResetInput, xFrontendDomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка токена сброса пароля и смена пароля
     * @param {PasswordResetVerifyInput} passwordResetVerifyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public emailControllerPasswordResetVerifyToken(passwordResetVerifyInput: PasswordResetVerifyInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).emailControllerPasswordResetVerifyToken(passwordResetVerifyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Логин в пользовательском приложении
     * @param {EmailSignInInput} emailSignInInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public emailControllerSignIn(emailSignInInput: EmailSignInInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).emailControllerSignIn(emailSignInInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация в пользовательском приложении
     * @param {EmailSignUpInput} emailSignUpInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public emailControllerSignUp(emailSignUpInput: EmailSignUpInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).emailControllerSignUp(emailSignUpInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление Google провайдера входа
     * @param {GoogleProviderCreateInput} googleProviderCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleControllerCreate(googleProviderCreateInput: GoogleProviderCreateInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleControllerCreate(googleProviderCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновляет данные google аккаунта (после изменения прав доступа)
     * @param {GoogleProviderCreateInput} googleProviderCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleControllerRefresh(googleProviderCreateInput: GoogleProviderCreateInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleControllerRefresh(googleProviderCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Логин через google
     * @param {GoogleSignInInput} googleSignInInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleControllerSignIn(googleSignInInput: GoogleSignInInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleControllerSignIn(googleSignInInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Логин через google (с созданием пользователя)
     * @param {GoogleSignUpInput} googleSignUpInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleControllerSignUp(googleSignUpInput: GoogleSignUpInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleControllerSignUp(googleSignUpInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление telegram провайдера входа
     * @param {TelegramProviderCreateInput} telegramProviderCreateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public telegramControllerCreate(telegramProviderCreateInput: TelegramProviderCreateInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).telegramControllerCreate(telegramProviderCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Логин через telegram
     * @param {TelegramSignInInput} telegramSignInInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public telegramControllerSignIn(telegramSignInInput: TelegramSignInInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).telegramControllerSignIn(telegramSignInInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Логин через telegram (с созданием пользователя)
     * @param {TelegramSignUpInput} telegramSignUpInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public telegramControllerSignUp(telegramSignUpInput: TelegramSignUpInput, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).telegramControllerSignUp(telegramSignUpInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceReminingApi - axios parameter creator
 * @export
 */
export const BalanceReminingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Возвращает на сколько часов хватит баланса при текущем расписании списаний анкет и салонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceRemainingControllerGetRestBalanceTime: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/profile/balance/remaining`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceReminingApi - functional programming interface
 * @export
 */
export const BalanceReminingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceReminingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Возвращает на сколько часов хватит баланса при текущем расписании списаний анкет и салонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceRemainingControllerGetRestBalanceTime(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestBalanceTime>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balanceRemainingControllerGetRestBalanceTime(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceReminingApi.balanceRemainingControllerGetRestBalanceTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BalanceReminingApi - factory interface
 * @export
 */
export const BalanceReminingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceReminingApiFp(configuration)
    return {
        /**
         * 
         * @summary Возвращает на сколько часов хватит баланса при текущем расписании списаний анкет и салонов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceRemainingControllerGetRestBalanceTime(options?: any): AxiosPromise<RestBalanceTime> {
            return localVarFp.balanceRemainingControllerGetRestBalanceTime(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceReminingApi - object-oriented interface
 * @export
 * @class BalanceReminingApi
 * @extends {BaseAPI}
 */
export class BalanceReminingApi extends BaseAPI {
    /**
     * 
     * @summary Возвращает на сколько часов хватит баланса при текущем расписании списаний анкет и салонов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceReminingApi
     */
    public balanceRemainingControllerGetRestBalanceTime(options?: RawAxiosRequestConfig) {
        return BalanceReminingApiFp(this.configuration).balanceRemainingControllerGetRestBalanceTime(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BannersApi - axios parameter creator
 * @export
 */
export const BannersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка баннеров на сайт
         * @param {number} [cityId] id города, который выбрал пользователь
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerGetBanners: async (cityId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannersApi - functional programming interface
 * @export
 */
export const BannersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка баннеров на сайт
         * @param {number} [cityId] id города, который выбрал пользователь
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bannerControllerGetBanners(cityId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bannerControllerGetBanners(cityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BannersApi.bannerControllerGetBanners']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BannersApi - factory interface
 * @export
 */
export const BannersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannersApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка баннеров на сайт
         * @param {number} [cityId] id города, который выбрал пользователь
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerControllerGetBanners(cityId?: number, options?: any): AxiosPromise<Array<Banner>> {
            return localVarFp.bannerControllerGetBanners(cityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannersApi - object-oriented interface
 * @export
 * @class BannersApi
 * @extends {BaseAPI}
 */
export class BannersApi extends BaseAPI {
    /**
     * 
     * @summary Получение списка баннеров на сайт
     * @param {number} [cityId] id города, который выбрал пользователь
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public bannerControllerGetBanners(cityId?: number, options?: RawAxiosRequestConfig) {
        return BannersApiFp(this.configuration).bannerControllerGetBanners(cityId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление комментария к анкете
         * @param {number} id 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerCreate: async (id: number, personCommentCreate: PersonCommentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personCommentControllerCreate', 'id', id)
            // verify required parameter 'personCommentCreate' is not null or undefined
            assertParamExists('personCommentControllerCreate', 'personCommentCreate', personCommentCreate)
            const localVarPath = `/api/v1/persons/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCommentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к анкете
         * @param {number} id 
         * @param {number} commentId 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerCreateAnswer: async (id: number, commentId: number, personCommentCreate: PersonCommentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personCommentControllerCreateAnswer', 'id', id)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('personCommentControllerCreateAnswer', 'commentId', commentId)
            // verify required parameter 'personCommentCreate' is not null or undefined
            assertParamExists('personCommentControllerCreateAnswer', 'personCommentCreate', personCommentCreate)
            const localVarPath = `/api/v1/persons/{id}/comments/{commentId}/answer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCommentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение комментариев к анкете
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerFindAllPersonComments: async (id: number, limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personCommentControllerFindAllPersonComments', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('personCommentControllerFindAllPersonComments', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('personCommentControllerFindAllPersonComments', 'offset', offset)
            const localVarPath = `/api/v1/persons/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление комментария к салону
         * @param {number} id 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerCreate: async (id: number, salonCommentCreate: SalonCommentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonCommentControllerCreate', 'id', id)
            // verify required parameter 'salonCommentCreate' is not null or undefined
            assertParamExists('salonCommentControllerCreate', 'salonCommentCreate', salonCommentCreate)
            const localVarPath = `/api/v1/salons/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salonCommentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к салону
         * @param {number} id 
         * @param {number} commentId 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerCreateAnswer: async (id: number, commentId: number, salonCommentCreate: SalonCommentCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonCommentControllerCreateAnswer', 'id', id)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('salonCommentControllerCreateAnswer', 'commentId', commentId)
            // verify required parameter 'salonCommentCreate' is not null or undefined
            assertParamExists('salonCommentControllerCreateAnswer', 'salonCommentCreate', salonCommentCreate)
            const localVarPath = `/api/v1/salons/{id}/comments/{commentId}/answer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salonCommentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение комментариев к салону
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerFindAll: async (id: number, limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonCommentControllerFindAll', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('salonCommentControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('salonCommentControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/salons/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавление комментария к анкете
         * @param {number} id 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personCommentControllerCreate(id: number, personCommentCreate: PersonCommentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personCommentControllerCreate(id, personCommentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.personCommentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к анкете
         * @param {number} id 
         * @param {number} commentId 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personCommentControllerCreateAnswer(id: number, commentId: number, personCommentCreate: PersonCommentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personCommentControllerCreateAnswer(id, commentId, personCommentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.personCommentControllerCreateAnswer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение комментариев к анкете
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personCommentControllerFindAllPersonComments(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCommentControllerFindAllPersonComments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personCommentControllerFindAllPersonComments(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.personCommentControllerFindAllPersonComments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление комментария к салону
         * @param {number} id 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonCommentControllerCreate(id: number, salonCommentCreate: SalonCommentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonCommentControllerCreate(id, salonCommentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.salonCommentControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к салону
         * @param {number} id 
         * @param {number} commentId 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonCommentControllerCreateAnswer(id: number, commentId: number, salonCommentCreate: SalonCommentCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonCommentControllerCreateAnswer(id, commentId, salonCommentCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.salonCommentControllerCreateAnswer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение комментариев к салону
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonCommentControllerFindAll(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonCommentControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonCommentControllerFindAll(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommentsApi.salonCommentControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавление комментария к анкете
         * @param {number} id 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerCreate(id: number, personCommentCreate: PersonCommentCreate, options?: any): AxiosPromise<PersonComment> {
            return localVarFp.personCommentControllerCreate(id, personCommentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к анкете
         * @param {number} id 
         * @param {number} commentId 
         * @param {PersonCommentCreate} personCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerCreateAnswer(id: number, commentId: number, personCommentCreate: PersonCommentCreate, options?: any): AxiosPromise<PersonComment> {
            return localVarFp.personCommentControllerCreateAnswer(id, commentId, personCommentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение комментариев к анкете
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personCommentControllerFindAllPersonComments(id: number, limit: number, offset: number, options?: any): AxiosPromise<PersonCommentControllerFindAllPersonComments200Response> {
            return localVarFp.personCommentControllerFindAllPersonComments(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление комментария к салону
         * @param {number} id 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerCreate(id: number, salonCommentCreate: SalonCommentCreate, options?: any): AxiosPromise<SalonComment> {
            return localVarFp.salonCommentControllerCreate(id, salonCommentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление ответа на комментарий к салону
         * @param {number} id 
         * @param {number} commentId 
         * @param {SalonCommentCreate} salonCommentCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerCreateAnswer(id: number, commentId: number, salonCommentCreate: SalonCommentCreate, options?: any): AxiosPromise<SalonComment> {
            return localVarFp.salonCommentControllerCreateAnswer(id, commentId, salonCommentCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение комментариев к салону
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonCommentControllerFindAll(id: number, limit: number, offset: number, options?: any): AxiosPromise<SalonCommentControllerFindAll200Response> {
            return localVarFp.salonCommentControllerFindAll(id, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI {
    /**
     * 
     * @summary Добавление комментария к анкете
     * @param {number} id 
     * @param {PersonCommentCreate} personCommentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public personCommentControllerCreate(id: number, personCommentCreate: PersonCommentCreate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).personCommentControllerCreate(id, personCommentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление ответа на комментарий к анкете
     * @param {number} id 
     * @param {number} commentId 
     * @param {PersonCommentCreate} personCommentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public personCommentControllerCreateAnswer(id: number, commentId: number, personCommentCreate: PersonCommentCreate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).personCommentControllerCreateAnswer(id, commentId, personCommentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение комментариев к анкете
     * @param {number} id 
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public personCommentControllerFindAllPersonComments(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).personCommentControllerFindAllPersonComments(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление комментария к салону
     * @param {number} id 
     * @param {SalonCommentCreate} salonCommentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public salonCommentControllerCreate(id: number, salonCommentCreate: SalonCommentCreate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).salonCommentControllerCreate(id, salonCommentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление ответа на комментарий к салону
     * @param {number} id 
     * @param {number} commentId 
     * @param {SalonCommentCreate} salonCommentCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public salonCommentControllerCreateAnswer(id: number, commentId: number, salonCommentCreate: SalonCommentCreate, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).salonCommentControllerCreateAnswer(id, commentId, salonCommentCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение комментариев к салону
     * @param {number} id 
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public salonCommentControllerFindAll(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return CommentsApiFp(this.configuration).salonCommentControllerFindAll(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение контактов анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personContactControllerGetContactsById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personContactControllerGetContactsById', 'id', id)
            const localVarPath = `/api/v1/persons/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение контактов салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonContactControllerGetSalonContacts: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonContactControllerGetSalonContacts', 'id', id)
            const localVarPath = `/api/v1/salons/{id}/contacts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение контактов анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personContactControllerGetContactsById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonContacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personContactControllerGetContactsById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.personContactControllerGetContactsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение контактов салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonContactControllerGetSalonContacts(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonContacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonContactControllerGetSalonContacts(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContactsApi.salonContactControllerGetSalonContacts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение контактов анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personContactControllerGetContactsById(id: number, options?: any): AxiosPromise<PersonContacts> {
            return localVarFp.personContactControllerGetContactsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение контактов салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonContactControllerGetSalonContacts(id: number, options?: any): AxiosPromise<SalonContacts> {
            return localVarFp.salonContactControllerGetSalonContacts(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
    /**
     * 
     * @summary Получение контактов анкеты
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public personContactControllerGetContactsById(id: number, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).personContactControllerGetContactsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение контактов салона
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactsApi
     */
    public salonContactControllerGetSalonContacts(id: number, options?: RawAxiosRequestConfig) {
        return ContactsApiFp(this.configuration).salonContactControllerGetSalonContacts(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerProfileApi - axios parameter creator
 * @export
 */
export const CustomerProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerProfileControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customers/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerProfileControllerSetProfile: async (profileUpdateInput: ProfileUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateInput' is not null or undefined
            assertParamExists('customerProfileControllerSetProfile', 'profileUpdateInput', profileUpdateInput)
            const localVarPath = `/api/v1/customers/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerProfileApi - functional programming interface
 * @export
 */
export const CustomerProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerProfileControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerProfileControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProfileApi.customerProfileControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerProfileControllerSetProfile(profileUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerProfileApi.customerProfileControllerSetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerProfileApi - factory interface
 * @export
 */
export const CustomerProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerProfileControllerGetProfile(options?: any): AxiosPromise<Profile> {
            return localVarFp.customerProfileControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: any): AxiosPromise<Profile> {
            return localVarFp.customerProfileControllerSetProfile(profileUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerProfileApi - object-oriented interface
 * @export
 * @class CustomerProfileApi
 * @extends {BaseAPI}
 */
export class CustomerProfileApi extends BaseAPI {
    /**
     * 
     * @summary Получение данных профиля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public customerProfileControllerGetProfile(options?: RawAxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).customerProfileControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение данных профиля
     * @param {ProfileUpdateInput} profileUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerProfileApi
     */
    public customerProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: RawAxiosRequestConfig) {
        return CustomerProfileApiFp(this.configuration).customerProfileControllerSetProfile(profileUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckControllerHealthcheck: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckControllerHealthcheck(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckControllerHealthcheck(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthcheckApi.healthcheckControllerHealthcheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckControllerHealthcheck(options?: any): AxiosPromise<void> {
            return localVarFp.healthcheckControllerHealthcheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public healthcheckControllerHealthcheck(options?: RawAxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).healthcheckControllerHealthcheck(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * I18nApi - axios parameter creator
 * @export
 */
export const I18nApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение локализованных названий
         * @param {string} model 
         * @param {number} id 
         * @param {string} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        i18nControllerGet: async (model: string, id: number, field?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('i18nControllerGet', 'model', model)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('i18nControllerGet', 'id', id)
            const localVarPath = `/api/v1/system/i18n`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * I18nApi - functional programming interface
 * @export
 */
export const I18nApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = I18nApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение локализованных названий
         * @param {string} model 
         * @param {number} id 
         * @param {string} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async i18nControllerGet(model: string, id: number, field?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<I18nTranslation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.i18nControllerGet(model, id, field, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['I18nApi.i18nControllerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * I18nApi - factory interface
 * @export
 */
export const I18nApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = I18nApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение локализованных названий
         * @param {string} model 
         * @param {number} id 
         * @param {string} [field] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        i18nControllerGet(model: string, id: number, field?: string, options?: any): AxiosPromise<I18nTranslation> {
            return localVarFp.i18nControllerGet(model, id, field, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * I18nApi - object-oriented interface
 * @export
 * @class I18nApi
 * @extends {BaseAPI}
 */
export class I18nApi extends BaseAPI {
    /**
     * 
     * @summary Получение локализованных названий
     * @param {string} model 
     * @param {number} id 
     * @param {string} [field] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof I18nApi
     */
    public i18nControllerGet(model: string, id: number, field?: string, options?: RawAxiosRequestConfig) {
        return I18nApiFp(this.configuration).i18nControllerGet(model, id, field, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ресайз изображений анкет
         * @param {number} mediaId id изображения
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerResize: async (mediaId: number, width?: number, height?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('imageControllerResize', 'mediaId', mediaId)
            const localVarPath = `/api/v1/system/images/resize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mediaId !== undefined) {
                localVarQueryParameter['mediaId'] = mediaId;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ресайз изображений анкет
         * @param {number} mediaId id изображения
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageControllerResize(mediaId: number, width?: number, height?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageControllerResize(mediaId, width, height, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageApi.imageControllerResize']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 
         * @summary Ресайз изображений анкет
         * @param {number} mediaId id изображения
         * @param {number} [width] width
         * @param {number} [height] height
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageControllerResize(mediaId: number, width?: number, height?: number, options?: any): AxiosPromise<string> {
            return localVarFp.imageControllerResize(mediaId, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @summary Ресайз изображений анкет
     * @param {number} mediaId id изображения
     * @param {number} [width] width
     * @param {number} [height] height
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public imageControllerResize(mediaId: number, width?: number, height?: number, options?: RawAxiosRequestConfig) {
        return ImageApiFp(this.configuration).imageControllerResize(mediaId, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удаление медиа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaControllerDelete', 'id', id)
            const localVarPath = `/api/v1/media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка медиа анкеты/салона
         * @param {File} [file] File to upload
         * @param {File} [preview] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaControllerUpload: async (file?: File, preview?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/media`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (preview !== undefined) { 
                localVarFormParams.append('preview', preview as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удаление медиа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.mediaControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузка медиа анкеты/салона
         * @param {File} [file] File to upload
         * @param {File} [preview] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaControllerUpload(file?: File, preview?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaControllerUpload(file, preview, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MediaApi.mediaControllerUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @summary Удаление медиа
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.mediaControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка медиа анкеты/салона
         * @param {File} [file] File to upload
         * @param {File} [preview] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaControllerUpload(file?: File, preview?: File, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.mediaControllerUpload(file, preview, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @summary Удаление медиа
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка медиа анкеты/салона
     * @param {File} [file] File to upload
     * @param {File} [preview] File to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public mediaControllerUpload(file?: File, preview?: File, options?: RawAxiosRequestConfig) {
        return MediaApiFp(this.configuration).mediaControllerUpload(file, preview, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetaApi - axios parameter creator
 * @export
 */
export const MetaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Принимает опциональный JWT токен
         * @summary Получение мета информации
         * @param {string} type Тип страницы для получения меты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGetMeta: async (type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('metaControllerGetMeta', 'type', type)
            const localVarPath = `/api/v1/meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaApiAxiosParamCreator(configuration)
    return {
        /**
         * Принимает опциональный JWT токен
         * @summary Получение мета информации
         * @param {string} type Тип страницы для получения меты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaControllerGetMeta(type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Meta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaControllerGetMeta(type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetaApi.metaControllerGetMeta']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaApiFp(configuration)
    return {
        /**
         * Принимает опциональный JWT токен
         * @summary Получение мета информации
         * @param {string} type Тип страницы для получения меты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGetMeta(type: string, options?: any): AxiosPromise<Meta> {
            return localVarFp.metaControllerGetMeta(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI {
    /**
     * Принимает опциональный JWT токен
     * @summary Получение мета информации
     * @param {string} type Тип страницы для получения меты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerGetMeta(type: string, options?: RawAxiosRequestConfig) {
        return MetaApiFp(this.configuration).metaControllerGetMeta(type, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение всех уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerFindAll: async (limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('notificationControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('notificationControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение новых уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerFindNew: async (limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('notificationControllerFindNew', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('notificationControllerFindNew', 'offset', offset)
            const localVarPath = `/api/v1/notifications/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение статуса уведомления
         * @param {string} id 
         * @param {UpdateNotificationStatus} updateNotificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerUpdateStatus: async (id: string, updateNotificationStatus: UpdateNotificationStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationControllerUpdateStatus', 'id', id)
            // verify required parameter 'updateNotificationStatus' is not null or undefined
            assertParamExists('notificationControllerUpdateStatus', 'updateNotificationStatus', updateNotificationStatus)
            const localVarPath = `/api/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение всех уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerFindAll(limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerFindAll(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение новых уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerFindNew(limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerFindNew(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationControllerFindNew']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Изменение статуса уведомления
         * @param {string} id 
         * @param {UpdateNotificationStatus} updateNotificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerUpdateStatus(id: string, updateNotificationStatus: UpdateNotificationStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerUpdateStatus(id, updateNotificationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.notificationControllerUpdateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение всех уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerFindAll(limit: number, offset: number, options?: any): AxiosPromise<NotificationControllerFindAll200Response> {
            return localVarFp.notificationControllerFindAll(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение новых уведомлений
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerFindNew(limit: number, offset: number, options?: any): AxiosPromise<NotificationControllerFindAll200Response> {
            return localVarFp.notificationControllerFindNew(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение статуса уведомления
         * @param {string} id 
         * @param {UpdateNotificationStatus} updateNotificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerUpdateStatus(id: string, updateNotificationStatus: UpdateNotificationStatus, options?: any): AxiosPromise<void> {
            return localVarFp.notificationControllerUpdateStatus(id, updateNotificationStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Получение всех уведомлений
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerFindAll(limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerFindAll(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение новых уведомлений
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerFindNew(limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerFindNew(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение статуса уведомления
     * @param {string} id 
     * @param {UpdateNotificationStatus} updateNotificationStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationControllerUpdateStatus(id: string, updateNotificationStatus: UpdateNotificationStatus, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).notificationControllerUpdateStatus(id, updateNotificationStatus, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonsApi - axios parameter creator
 * @export
 */
export const PersonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение анкет
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид сортировки
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindAll: async (limit: number, offset: number, seed?: string, search?: string, cityId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('personControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('personControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seed !== undefined) {
                localVarQueryParameter['seed'] = seed;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение анкеты по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personControllerFindById', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение подружек анкеты
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindFriendsById: async (id: number, limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personControllerFindFriendsById', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('personControllerFindFriendsById', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('personControllerFindFriendsById', 'offset', offset)
            const localVarPath = `/api/v1/persons/{id}/friends`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonsApi - functional programming interface
 * @export
 */
export const PersonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение анкет
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид сортировки
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerFindAll(limit, offset, seed, search, cityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonsApi.personControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение анкеты по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerFindById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerFindById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonsApi.personControllerFindById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение подружек анкеты
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personControllerFindFriendsById(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerFindFriendsById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personControllerFindFriendsById(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonsApi.personControllerFindFriendsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonsApi - factory interface
 * @export
 */
export const PersonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение анкет
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид сортировки
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, options?: any): AxiosPromise<PersonControllerFindAll200Response> {
            return localVarFp.personControllerFindAll(limit, offset, seed, search, cityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение анкеты по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindById(id: number, options?: any): AxiosPromise<PublicPersonDto> {
            return localVarFp.personControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение подружек анкеты
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personControllerFindFriendsById(id: number, limit: number, offset: number, options?: any): AxiosPromise<PersonControllerFindFriendsById200Response> {
            return localVarFp.personControllerFindFriendsById(id, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonsApi - object-oriented interface
 * @export
 * @class PersonsApi
 * @extends {BaseAPI}
 */
export class PersonsApi extends BaseAPI {
    /**
     * 
     * @summary Получение анкет
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {string} [seed] сид сортировки
     * @param {string} [search] 
     * @param {number} [cityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, options?: RawAxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personControllerFindAll(limit, offset, seed, search, cityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение анкеты по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personControllerFindById(id: number, options?: RawAxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение подружек анкеты
     * @param {number} id 
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personControllerFindFriendsById(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personControllerFindFriendsById(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublicMediaApi - axios parameter creator
 * @export
 */
export const PublicMediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получает ссылку и данные публичного медиа по slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicMediaControllerGetMedia: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('publicMediaControllerGetMedia', 'slug', slug)
            const localVarPath = `/api/v1/public-media/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicMediaApi - functional programming interface
 * @export
 */
export const PublicMediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicMediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получает ссылку и данные публичного медиа по slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicMediaControllerGetMedia(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicMediaControllerGetMedia(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicMediaApi.publicMediaControllerGetMedia']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicMediaApi - factory interface
 * @export
 */
export const PublicMediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicMediaApiFp(configuration)
    return {
        /**
         * 
         * @summary Получает ссылку и данные публичного медиа по slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicMediaControllerGetMedia(slug: string, options?: any): AxiosPromise<PublicMedia> {
            return localVarFp.publicMediaControllerGetMedia(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicMediaApi - object-oriented interface
 * @export
 * @class PublicMediaApi
 * @extends {BaseAPI}
 */
export class PublicMediaApi extends BaseAPI {
    /**
     * 
     * @summary Получает ссылку и данные публичного медиа по slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicMediaApi
     */
    public publicMediaControllerGetMedia(slug: string, options?: RawAxiosRequestConfig) {
        return PublicMediaApiFp(this.configuration).publicMediaControllerGetMedia(slug, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RateApi - axios parameter creator
 * @export
 */
export const RateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Конвертация валют в USD
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rateControllerConvert: async (id: number, amount: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rateControllerConvert', 'id', id)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('rateControllerConvert', 'amount', amount)
            const localVarPath = `/api/v1/system/rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RateApi - functional programming interface
 * @export
 */
export const RateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Конвертация валют в USD
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rateControllerConvert(id: number, amount: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rateControllerConvert(id, amount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RateApi.rateControllerConvert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RateApi - factory interface
 * @export
 */
export const RateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RateApiFp(configuration)
    return {
        /**
         * 
         * @summary Конвертация валют в USD
         * @param {number} id 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rateControllerConvert(id: number, amount: number, options?: any): AxiosPromise<RateResponse> {
            return localVarFp.rateControllerConvert(id, amount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RateApi - object-oriented interface
 * @export
 * @class RateApi
 * @extends {BaseAPI}
 */
export class RateApi extends BaseAPI {
    /**
     * 
     * @summary Конвертация валют в USD
     * @param {number} id 
     * @param {number} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RateApi
     */
    public rateControllerConvert(id: number, amount: number, options?: RawAxiosRequestConfig) {
        return RateApiFp(this.configuration).rateControllerConvert(id, amount, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RefsApi - axios parameter creator
 * @export
 */
export const RefsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuControllerCatalogFeatures: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/menu/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение городов для меню
         * @param {number} [limit] 
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuControllerCities: async (limit?: number, regionId?: number, countryId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/menu/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение платежных провайдеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerAccountProviders: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/account-providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCatalogFeatures: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение категорий каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение городов
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCities: async (regionId?: number, countryId?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение стран
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCountries: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение валют
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCurrencies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение районов
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerDistricts: async (cityId?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/districts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение брендов для пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerFindAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение станций метро
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerMetroStations: async (cityId?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/metro-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение редиректов
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerRedirects: async (path?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/redirects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение регионов
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerRegions: async (countryId?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение статичных страниц
         * @param {string} slug 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerStaticPages: async (slug: string, countryId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('refsControllerStaticPages', 'slug', slug)
            const localVarPath = `/api/v1/refs/static-pages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение тегов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerTags: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение телеграм каналов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerTelegramChannels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refs/telegram-channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefsApi - functional programming interface
 * @export
 */
export const RefsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuControllerCatalogFeatures(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuControllerCatalogFeatures(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.menuControllerCatalogFeatures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение городов для меню
         * @param {number} [limit] 
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuControllerCities(limit?: number, regionId?: number, countryId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuCity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuControllerCities(limit, regionId, countryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.menuControllerCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение платежных провайдеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerAccountProviders(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountProvider>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerAccountProviders(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerAccountProviders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerCatalogFeatures(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerCatalogFeatures(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerCatalogFeatures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение категорий каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение городов
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerCities(regionId?: number, countryId?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<City>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerCities(regionId, countryId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение стран
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerCountries(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerCountries(search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение валют
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerCurrencies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currency>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerCurrencies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerCurrencies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение районов
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerDistricts(cityId?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<District>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerDistricts(cityId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerDistricts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение брендов для пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerFindAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Brand>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerFindAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение станций метро
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerMetroStations(cityId?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetroStation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerMetroStations(cityId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerMetroStations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение редиректов
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerRedirects(path?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Redirect>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerRedirects(path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerRedirects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение регионов
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerRegions(countryId?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerRegions(countryId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение статичных страниц
         * @param {string} slug 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerStaticPages(slug: string, countryId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaticPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerStaticPages(slug, countryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerStaticPages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение тегов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerTags(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerTags(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение телеграм каналов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refsControllerTelegramChannels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TelegramChannel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refsControllerTelegramChannels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RefsApi.refsControllerTelegramChannels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RefsApi - factory interface
 * @export
 */
export const RefsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuControllerCatalogFeatures(options?: any): AxiosPromise<Array<Feature>> {
            return localVarFp.menuControllerCatalogFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение городов для меню
         * @param {number} [limit] 
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuControllerCities(limit?: number, regionId?: number, countryId?: number, options?: any): AxiosPromise<Array<MenuCity>> {
            return localVarFp.menuControllerCities(limit, regionId, countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение платежных провайдеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerAccountProviders(options?: any): AxiosPromise<Array<AccountProvider>> {
            return localVarFp.refsControllerAccountProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение фич каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCatalogFeatures(options?: any): AxiosPromise<Array<Feature>> {
            return localVarFp.refsControllerCatalogFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение категорий каталога
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCategories(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.refsControllerCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение городов
         * @param {number} [regionId] 
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCities(regionId?: number, countryId?: number, search?: string, options?: any): AxiosPromise<Array<City>> {
            return localVarFp.refsControllerCities(regionId, countryId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение стран
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCountries(search?: string, options?: any): AxiosPromise<Array<Country>> {
            return localVarFp.refsControllerCountries(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение валют
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerCurrencies(options?: any): AxiosPromise<Array<Currency>> {
            return localVarFp.refsControllerCurrencies(options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение районов
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerDistricts(cityId?: number, search?: string, options?: any): AxiosPromise<Array<District>> {
            return localVarFp.refsControllerDistricts(cityId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение брендов для пользователей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerFindAll(options?: any): AxiosPromise<Array<Brand>> {
            return localVarFp.refsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение станций метро
         * @param {number} [cityId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerMetroStations(cityId?: number, search?: string, options?: any): AxiosPromise<Array<MetroStation>> {
            return localVarFp.refsControllerMetroStations(cityId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение редиректов
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerRedirects(path?: string, options?: any): AxiosPromise<Array<Redirect>> {
            return localVarFp.refsControllerRedirects(path, options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение регионов
         * @param {number} [countryId] 
         * @param {string} [search] поиск
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerRegions(countryId?: number, search?: string, options?: any): AxiosPromise<Array<Region>> {
            return localVarFp.refsControllerRegions(countryId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение статичных страниц
         * @param {string} slug 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerStaticPages(slug: string, countryId?: number, options?: any): AxiosPromise<StaticPage> {
            return localVarFp.refsControllerStaticPages(slug, countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Принимает опциональный JWT токен
         * @summary Получение тегов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerTags(options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.refsControllerTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение телеграм каналов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refsControllerTelegramChannels(options?: any): AxiosPromise<Array<TelegramChannel>> {
            return localVarFp.refsControllerTelegramChannels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefsApi - object-oriented interface
 * @export
 * @class RefsApi
 * @extends {BaseAPI}
 */
export class RefsApi extends BaseAPI {
    /**
     * 
     * @summary Получение фич каталога
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public menuControllerCatalogFeatures(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).menuControllerCatalogFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение городов для меню
     * @param {number} [limit] 
     * @param {number} [regionId] 
     * @param {number} [countryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public menuControllerCities(limit?: number, regionId?: number, countryId?: number, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).menuControllerCities(limit, regionId, countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение платежных провайдеров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerAccountProviders(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerAccountProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение фич каталога
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerCatalogFeatures(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerCatalogFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение категорий каталога
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerCategories(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение городов
     * @param {number} [regionId] 
     * @param {number} [countryId] 
     * @param {string} [search] поиск
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerCities(regionId?: number, countryId?: number, search?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerCities(regionId, countryId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение стран
     * @param {string} [search] поиск
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerCountries(search?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerCountries(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение валют
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerCurrencies(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerCurrencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение районов
     * @param {number} [cityId] 
     * @param {string} [search] поиск
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerDistricts(cityId?: number, search?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerDistricts(cityId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение брендов для пользователей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerFindAll(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение станций метро
     * @param {number} [cityId] 
     * @param {string} [search] поиск
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerMetroStations(cityId?: number, search?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerMetroStations(cityId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение редиректов
     * @param {string} [path] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerRedirects(path?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerRedirects(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение регионов
     * @param {number} [countryId] 
     * @param {string} [search] поиск
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerRegions(countryId?: number, search?: string, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerRegions(countryId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение статичных страниц
     * @param {string} slug 
     * @param {number} [countryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerStaticPages(slug: string, countryId?: number, options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerStaticPages(slug, countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Принимает опциональный JWT токен
     * @summary Получение тегов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerTags(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение телеграм каналов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefsApi
     */
    public refsControllerTelegramChannels(options?: RawAxiosRequestConfig) {
        return RefsApiFp(this.configuration).refsControllerTelegramChannels(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReplenishApi - axios parameter creator
 * @export
 */
export const ReplenishApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание запросов на пополнение
         * @param {number} accountProviderId id платежного провайдера
         * @param {number} amount пополнение в валюте accountProvider-а
         * @param {File} [file] файл чека
         * @param {string} [userComment] пользовательский комментарий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishControllerCreateReplenish: async (accountProviderId: number, amount: number, file?: File, userComment?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountProviderId' is not null or undefined
            assertParamExists('replenishControllerCreateReplenish', 'accountProviderId', accountProviderId)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('replenishControllerCreateReplenish', 'amount', amount)
            const localVarPath = `/api/v1/users/replenish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (accountProviderId !== undefined) { 
                localVarFormParams.append('accountProviderId', accountProviderId as any);
            }
    
            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }
    
            if (userComment !== undefined) { 
                localVarFormParams.append('userComment', userComment as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр запросов на пополнение
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {ReplenishControllerFindAllStatusEnum} [status] статус запроса на пополнение
         * @param {number} [amountMin] фильтр по количеству (нижняя граница)
         * @param {number} [amountMax] фильтр по количеству (верхняя граница)
         * @param {string} [startDateMin] фильтр по дате создания (нижняя граница)
         * @param {string} [startDateMax] фильтр по дате создания (верхняя граница)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishControllerFindAll: async (limit: number, offset: number, status?: ReplenishControllerFindAllStatusEnum, amountMin?: number, amountMax?: number, startDateMin?: string, startDateMax?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('replenishControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('replenishControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/users/replenish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (amountMin !== undefined) {
                localVarQueryParameter['amountMin'] = amountMin;
            }

            if (amountMax !== undefined) {
                localVarQueryParameter['amountMax'] = amountMax;
            }

            if (startDateMin !== undefined) {
                localVarQueryParameter['startDateMin'] = (startDateMin as any instanceof Date) ?
                    (startDateMin as any).toISOString() :
                    startDateMin;
            }

            if (startDateMax !== undefined) {
                localVarQueryParameter['startDateMax'] = (startDateMax as any instanceof Date) ?
                    (startDateMax as any).toISOString() :
                    startDateMax;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReplenishApi - functional programming interface
 * @export
 */
export const ReplenishApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReplenishApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание запросов на пополнение
         * @param {number} accountProviderId id платежного провайдера
         * @param {number} amount пополнение в валюте accountProvider-а
         * @param {File} [file] файл чека
         * @param {string} [userComment] пользовательский комментарий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replenishControllerCreateReplenish(accountProviderId: number, amount: number, file?: File, userComment?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplenishTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replenishControllerCreateReplenish(accountProviderId, amount, file, userComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplenishApi.replenishControllerCreateReplenish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Просмотр запросов на пополнение
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {ReplenishControllerFindAllStatusEnum} [status] статус запроса на пополнение
         * @param {number} [amountMin] фильтр по количеству (нижняя граница)
         * @param {number} [amountMax] фильтр по количеству (верхняя граница)
         * @param {string} [startDateMin] фильтр по дате создания (нижняя граница)
         * @param {string} [startDateMax] фильтр по дате создания (верхняя граница)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replenishControllerFindAll(limit: number, offset: number, status?: ReplenishControllerFindAllStatusEnum, amountMin?: number, amountMax?: number, startDateMin?: string, startDateMax?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replenishControllerFindAll(limit, offset, status, amountMin, amountMax, startDateMin, startDateMax, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReplenishApi.replenishControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReplenishApi - factory interface
 * @export
 */
export const ReplenishApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReplenishApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание запросов на пополнение
         * @param {number} accountProviderId id платежного провайдера
         * @param {number} amount пополнение в валюте accountProvider-а
         * @param {File} [file] файл чека
         * @param {string} [userComment] пользовательский комментарий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishControllerCreateReplenish(accountProviderId: number, amount: number, file?: File, userComment?: string, options?: any): AxiosPromise<ReplenishTransaction> {
            return localVarFp.replenishControllerCreateReplenish(accountProviderId, amount, file, userComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Просмотр запросов на пополнение
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {ReplenishControllerFindAllStatusEnum} [status] статус запроса на пополнение
         * @param {number} [amountMin] фильтр по количеству (нижняя граница)
         * @param {number} [amountMax] фильтр по количеству (верхняя граница)
         * @param {string} [startDateMin] фильтр по дате создания (нижняя граница)
         * @param {string} [startDateMax] фильтр по дате создания (верхняя граница)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replenishControllerFindAll(limit: number, offset: number, status?: ReplenishControllerFindAllStatusEnum, amountMin?: number, amountMax?: number, startDateMin?: string, startDateMax?: string, options?: any): AxiosPromise<void> {
            return localVarFp.replenishControllerFindAll(limit, offset, status, amountMin, amountMax, startDateMin, startDateMax, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReplenishApi - object-oriented interface
 * @export
 * @class ReplenishApi
 * @extends {BaseAPI}
 */
export class ReplenishApi extends BaseAPI {
    /**
     * 
     * @summary Создание запросов на пополнение
     * @param {number} accountProviderId id платежного провайдера
     * @param {number} amount пополнение в валюте accountProvider-а
     * @param {File} [file] файл чека
     * @param {string} [userComment] пользовательский комментарий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplenishApi
     */
    public replenishControllerCreateReplenish(accountProviderId: number, amount: number, file?: File, userComment?: string, options?: RawAxiosRequestConfig) {
        return ReplenishApiFp(this.configuration).replenishControllerCreateReplenish(accountProviderId, amount, file, userComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Просмотр запросов на пополнение
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {ReplenishControllerFindAllStatusEnum} [status] статус запроса на пополнение
     * @param {number} [amountMin] фильтр по количеству (нижняя граница)
     * @param {number} [amountMax] фильтр по количеству (верхняя граница)
     * @param {string} [startDateMin] фильтр по дате создания (нижняя граница)
     * @param {string} [startDateMax] фильтр по дате создания (верхняя граница)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplenishApi
     */
    public replenishControllerFindAll(limit: number, offset: number, status?: ReplenishControllerFindAllStatusEnum, amountMin?: number, amountMax?: number, startDateMin?: string, startDateMax?: string, options?: RawAxiosRequestConfig) {
        return ReplenishApiFp(this.configuration).replenishControllerFindAll(limit, offset, status, amountMin, amountMax, startDateMin, startDateMax, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ReplenishControllerFindAllStatusEnum = {
    New: 'new',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;
export type ReplenishControllerFindAllStatusEnum = typeof ReplenishControllerFindAllStatusEnum[keyof typeof ReplenishControllerFindAllStatusEnum];


/**
 * SalonsApi - axios parameter creator
 * @export
 */
export const SalonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид случайной выдачи
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindAll: async (limit: number, offset: number, seed?: string, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('salonControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('salonControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/salons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seed !== undefined) {
                localVarQueryParameter['seed'] = seed;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (districtId !== undefined) {
                localVarQueryParameter['districtId'] = districtId;
            }

            if (metroStationIds) {
                localVarQueryParameter['metroStationIds'] = metroStationIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение салона по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonControllerFindById', 'id', id)
            const localVarPath = `/api/v1/salons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение анкет салона
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindPersonsBySalon: async (id: number, limit: number, offset: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonControllerFindPersonsBySalon', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('salonControllerFindPersonsBySalon', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('salonControllerFindPersonsBySalon', 'offset', offset)
            const localVarPath = `/api/v1/salons/{id}/persons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalonsApi - functional programming interface
 * @export
 */
export const SalonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид случайной выдачи
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerFindAll(limit, offset, seed, search, cityId, districtId, metroStationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalonsApi.salonControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение салона по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerFindById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSalonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerFindById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalonsApi.salonControllerFindById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение анкет салона
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonControllerFindPersonsBySalon(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonControllerFindFriendsById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonControllerFindPersonsBySalon(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SalonsApi.salonControllerFindPersonsBySalon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SalonsApi - factory interface
 * @export
 */
export const SalonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [seed] сид случайной выдачи
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: any): AxiosPromise<SalonControllerFindAll200Response> {
            return localVarFp.salonControllerFindAll(limit, offset, seed, search, cityId, districtId, metroStationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение салона по id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindById(id: number, options?: any): AxiosPromise<PublicSalonDto> {
            return localVarFp.salonControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение анкет салона
         * @param {number} id 
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonControllerFindPersonsBySalon(id: number, limit: number, offset: number, options?: any): AxiosPromise<PersonControllerFindFriendsById200Response> {
            return localVarFp.salonControllerFindPersonsBySalon(id, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalonsApi - object-oriented interface
 * @export
 * @class SalonsApi
 * @extends {BaseAPI}
 */
export class SalonsApi extends BaseAPI {
    /**
     * 
     * @summary Получение салонов
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {string} [seed] сид случайной выдачи
     * @param {string} [search] 
     * @param {number} [cityId] 
     * @param {number} [districtId] 
     * @param {Array<number>} [metroStationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerFindAll(limit: number, offset: number, seed?: string, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: RawAxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerFindAll(limit, offset, seed, search, cityId, districtId, metroStationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение салона по id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerFindById(id: number, options?: RawAxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение анкет салона
     * @param {number} id 
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalonsApi
     */
    public salonControllerFindPersonsBySalon(id: number, limit: number, offset: number, options?: RawAxiosRequestConfig) {
        return SalonsApiFp(this.configuration).salonControllerFindPersonsBySalon(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка транзакций
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {UserTransactionsControllerFindAllEntityTypeEnum} [entityType] фильтр по типу сущности
         * @param {boolean} [inProgress] выводить только активные транзакции
         * @param {number} [entityId] фильтр по id сущности
         * @param {string} [start] фильтр на дату начала транзакции
         * @param {string} [end] фильтр на дату конца транзакции (активные не учитываются)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTransactionsControllerFindAll: async (limit: number, offset: number, entityType?: UserTransactionsControllerFindAllEntityTypeEnum, inProgress?: boolean, entityId?: number, start?: string, end?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userTransactionsControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userTransactionsControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/users/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (inProgress !== undefined) {
                localVarQueryParameter['inProgress'] = inProgress;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка транзакций
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {UserTransactionsControllerFindAllEntityTypeEnum} [entityType] фильтр по типу сущности
         * @param {boolean} [inProgress] выводить только активные транзакции
         * @param {number} [entityId] фильтр по id сущности
         * @param {string} [start] фильтр на дату начала транзакции
         * @param {string} [end] фильтр на дату конца транзакции (активные не учитываются)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userTransactionsControllerFindAll(limit: number, offset: number, entityType?: UserTransactionsControllerFindAllEntityTypeEnum, inProgress?: boolean, entityId?: number, start?: string, end?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTransactionsControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userTransactionsControllerFindAll(limit, offset, entityType, inProgress, entityId, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.userTransactionsControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка транзакций
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {UserTransactionsControllerFindAllEntityTypeEnum} [entityType] фильтр по типу сущности
         * @param {boolean} [inProgress] выводить только активные транзакции
         * @param {number} [entityId] фильтр по id сущности
         * @param {string} [start] фильтр на дату начала транзакции
         * @param {string} [end] фильтр на дату конца транзакции (активные не учитываются)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userTransactionsControllerFindAll(limit: number, offset: number, entityType?: UserTransactionsControllerFindAllEntityTypeEnum, inProgress?: boolean, entityId?: number, start?: string, end?: string, options?: any): AxiosPromise<UserTransactionsControllerFindAll200Response> {
            return localVarFp.userTransactionsControllerFindAll(limit, offset, entityType, inProgress, entityId, start, end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary Получение списка транзакций
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {UserTransactionsControllerFindAllEntityTypeEnum} [entityType] фильтр по типу сущности
     * @param {boolean} [inProgress] выводить только активные транзакции
     * @param {number} [entityId] фильтр по id сущности
     * @param {string} [start] фильтр на дату начала транзакции
     * @param {string} [end] фильтр на дату конца транзакции (активные не учитываются)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public userTransactionsControllerFindAll(limit: number, offset: number, entityType?: UserTransactionsControllerFindAllEntityTypeEnum, inProgress?: boolean, entityId?: number, start?: string, end?: string, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).userTransactionsControllerFindAll(limit, offset, entityType, inProgress, entityId, start, end, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const UserTransactionsControllerFindAllEntityTypeEnum = {
    Salon: 'salon',
    Person: 'person'
} as const;
export type UserTransactionsControllerFindAllEntityTypeEnum = typeof UserTransactionsControllerFindAllEntityTypeEnum[keyof typeof UserTransactionsControllerFindAllEntityTypeEnum];


/**
 * UserPersonFriendsApi - axios parameter creator
 * @export
 */
export const UserPersonFriendsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удаление из подружек
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerDelete: async (senderId: number, receiverId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'senderId' is not null or undefined
            assertParamExists('friendControllerDelete', 'senderId', senderId)
            // verify required parameter 'receiverId' is not null or undefined
            assertParamExists('friendControllerDelete', 'receiverId', receiverId)
            const localVarPath = `/api/v1/users/persons/{senderId}/requests/{receiverId}`
                .replace(`{${"senderId"}}`, encodeURIComponent(String(senderId)))
                .replace(`{${"receiverId"}}`, encodeURIComponent(String(receiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка подружек
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerGetFriends: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('friendControllerGetFriends', 'id', id)
            const localVarPath = `/api/v1/users/persons/{id}/friends`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Просмотр исходящих и входящих заявок в подружки
         * @param {number} id 
         * @param {FriendControllerGetRequestsTypeEnum} type тип запроса
         * @param {FriendControllerGetRequestsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerGetRequests: async (id: number, type: FriendControllerGetRequestsTypeEnum, status?: FriendControllerGetRequestsStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('friendControllerGetRequests', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('friendControllerGetRequests', 'type', type)
            const localVarPath = `/api/v1/users/persons/{id}/friends/requests`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка заявки в подружки
         * @param {number} id 
         * @param {FriendshipRequestCreate} friendshipRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerSendRequest: async (id: number, friendshipRequestCreate: FriendshipRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('friendControllerSendRequest', 'id', id)
            // verify required parameter 'friendshipRequestCreate' is not null or undefined
            assertParamExists('friendControllerSendRequest', 'friendshipRequestCreate', friendshipRequestCreate)
            const localVarPath = `/api/v1/users/persons/{id}/friends/requests`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(friendshipRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение статуса заявки в подружки
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {FriendRequestUpdate} friendRequestUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerUpdateStatus: async (senderId: number, receiverId: number, friendRequestUpdate: FriendRequestUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'senderId' is not null or undefined
            assertParamExists('friendControllerUpdateStatus', 'senderId', senderId)
            // verify required parameter 'receiverId' is not null or undefined
            assertParamExists('friendControllerUpdateStatus', 'receiverId', receiverId)
            // verify required parameter 'friendRequestUpdate' is not null or undefined
            assertParamExists('friendControllerUpdateStatus', 'friendRequestUpdate', friendRequestUpdate)
            const localVarPath = `/api/v1/users/persons/{senderId}/requests/{receiverId}`
                .replace(`{${"senderId"}}`, encodeURIComponent(String(senderId)))
                .replace(`{${"receiverId"}}`, encodeURIComponent(String(receiverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(friendRequestUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPersonFriendsApi - functional programming interface
 * @export
 */
export const UserPersonFriendsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPersonFriendsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удаление из подружек
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendControllerDelete(senderId: number, receiverId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendControllerDelete(senderId, receiverId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonFriendsApi.friendControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение списка подружек
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendControllerGetFriends(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicPersonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendControllerGetFriends(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonFriendsApi.friendControllerGetFriends']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Просмотр исходящих и входящих заявок в подружки
         * @param {number} id 
         * @param {FriendControllerGetRequestsTypeEnum} type тип запроса
         * @param {FriendControllerGetRequestsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendControllerGetRequests(id: number, type: FriendControllerGetRequestsTypeEnum, status?: FriendControllerGetRequestsStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FriendshipRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendControllerGetRequests(id, type, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonFriendsApi.friendControllerGetRequests']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Отправка заявки в подружки
         * @param {number} id 
         * @param {FriendshipRequestCreate} friendshipRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendControllerSendRequest(id: number, friendshipRequestCreate: FriendshipRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendControllerSendRequest(id, friendshipRequestCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonFriendsApi.friendControllerSendRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Изменение статуса заявки в подружки
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {FriendRequestUpdate} friendRequestUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendControllerUpdateStatus(senderId: number, receiverId: number, friendRequestUpdate: FriendRequestUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendControllerUpdateStatus(senderId, receiverId, friendRequestUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonFriendsApi.friendControllerUpdateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPersonFriendsApi - factory interface
 * @export
 */
export const UserPersonFriendsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPersonFriendsApiFp(configuration)
    return {
        /**
         * 
         * @summary Удаление из подружек
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerDelete(senderId: number, receiverId: number, options?: any): AxiosPromise<void> {
            return localVarFp.friendControllerDelete(senderId, receiverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка подружек
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerGetFriends(id: number, options?: any): AxiosPromise<Array<PublicPersonDto>> {
            return localVarFp.friendControllerGetFriends(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Просмотр исходящих и входящих заявок в подружки
         * @param {number} id 
         * @param {FriendControllerGetRequestsTypeEnum} type тип запроса
         * @param {FriendControllerGetRequestsStatusEnum} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerGetRequests(id: number, type: FriendControllerGetRequestsTypeEnum, status?: FriendControllerGetRequestsStatusEnum, options?: any): AxiosPromise<Array<FriendshipRequest>> {
            return localVarFp.friendControllerGetRequests(id, type, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка заявки в подружки
         * @param {number} id 
         * @param {FriendshipRequestCreate} friendshipRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerSendRequest(id: number, friendshipRequestCreate: FriendshipRequestCreate, options?: any): AxiosPromise<void> {
            return localVarFp.friendControllerSendRequest(id, friendshipRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение статуса заявки в подружки
         * @param {number} senderId 
         * @param {number} receiverId 
         * @param {FriendRequestUpdate} friendRequestUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendControllerUpdateStatus(senderId: number, receiverId: number, friendRequestUpdate: FriendRequestUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.friendControllerUpdateStatus(senderId, receiverId, friendRequestUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPersonFriendsApi - object-oriented interface
 * @export
 * @class UserPersonFriendsApi
 * @extends {BaseAPI}
 */
export class UserPersonFriendsApi extends BaseAPI {
    /**
     * 
     * @summary Удаление из подружек
     * @param {number} senderId 
     * @param {number} receiverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonFriendsApi
     */
    public friendControllerDelete(senderId: number, receiverId: number, options?: RawAxiosRequestConfig) {
        return UserPersonFriendsApiFp(this.configuration).friendControllerDelete(senderId, receiverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка подружек
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonFriendsApi
     */
    public friendControllerGetFriends(id: number, options?: RawAxiosRequestConfig) {
        return UserPersonFriendsApiFp(this.configuration).friendControllerGetFriends(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Просмотр исходящих и входящих заявок в подружки
     * @param {number} id 
     * @param {FriendControllerGetRequestsTypeEnum} type тип запроса
     * @param {FriendControllerGetRequestsStatusEnum} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonFriendsApi
     */
    public friendControllerGetRequests(id: number, type: FriendControllerGetRequestsTypeEnum, status?: FriendControllerGetRequestsStatusEnum, options?: RawAxiosRequestConfig) {
        return UserPersonFriendsApiFp(this.configuration).friendControllerGetRequests(id, type, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка заявки в подружки
     * @param {number} id 
     * @param {FriendshipRequestCreate} friendshipRequestCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonFriendsApi
     */
    public friendControllerSendRequest(id: number, friendshipRequestCreate: FriendshipRequestCreate, options?: RawAxiosRequestConfig) {
        return UserPersonFriendsApiFp(this.configuration).friendControllerSendRequest(id, friendshipRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение статуса заявки в подружки
     * @param {number} senderId 
     * @param {number} receiverId 
     * @param {FriendRequestUpdate} friendRequestUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonFriendsApi
     */
    public friendControllerUpdateStatus(senderId: number, receiverId: number, friendRequestUpdate: FriendRequestUpdate, options?: RawAxiosRequestConfig) {
        return UserPersonFriendsApiFp(this.configuration).friendControllerUpdateStatus(senderId, receiverId, friendRequestUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FriendControllerGetRequestsTypeEnum = {
    Incoming: 'incoming',
    Outcoming: 'outcoming'
} as const;
export type FriendControllerGetRequestsTypeEnum = typeof FriendControllerGetRequestsTypeEnum[keyof typeof FriendControllerGetRequestsTypeEnum];
/**
 * @export
 */
export const FriendControllerGetRequestsStatusEnum = {
    Sent: 'sent',
    Approved: 'approved',
    Rejected: 'rejected',
    Canceled: 'canceled'
} as const;
export type FriendControllerGetRequestsStatusEnum = typeof FriendControllerGetRequestsStatusEnum[keyof typeof FriendControllerGetRequestsStatusEnum];


/**
 * UserPersonPublishingApi - axios parameter creator
 * @export
 */
export const UserPersonPublishingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение статуса расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerGetPublishStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personPublishControllerGetPublishStatus', 'id', id)
            const localVarPath = `/api/v1/users/persons/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerGetSchedule: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personPublishControllerGetSchedule', 'id', id)
            const localVarPath = `/api/v1/users/persons/{id}/publish/schedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание/изменение расписания показов
         * @param {number} id 
         * @param {ScheduleUpdate} scheduleUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerSetSchedule: async (id: number, scheduleUpdate: ScheduleUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personPublishControllerSetSchedule', 'id', id)
            // verify required parameter 'scheduleUpdate' is not null or undefined
            assertParamExists('personPublishControllerSetSchedule', 'scheduleUpdate', scheduleUpdate)
            const localVarPath = `/api/v1/users/persons/{id}/publish/schedule`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переключение статуса опубликованности анкеты
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerTogglePublish: async (id: number, publishUpdateInput: PublishUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personPublishControllerTogglePublish', 'id', id)
            // verify required parameter 'publishUpdateInput' is not null or undefined
            assertParamExists('personPublishControllerTogglePublish', 'publishUpdateInput', publishUpdateInput)
            const localVarPath = `/api/v1/users/persons/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPersonPublishingApi - functional programming interface
 * @export
 */
export const UserPersonPublishingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPersonPublishingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение статуса расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personPublishControllerGetPublishStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishingStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personPublishControllerGetPublishStatus(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonPublishingApi.personPublishControllerGetPublishStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personPublishControllerGetSchedule(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personPublishControllerGetSchedule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonPublishingApi.personPublishControllerGetSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Создание/изменение расписания показов
         * @param {number} id 
         * @param {ScheduleUpdate} scheduleUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personPublishControllerSetSchedule(id: number, scheduleUpdate: ScheduleUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personPublishControllerSetSchedule(id, scheduleUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonPublishingApi.personPublishControllerSetSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Переключение статуса опубликованности анкеты
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personPublishControllerTogglePublish(id, publishUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonPublishingApi.personPublishControllerTogglePublish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPersonPublishingApi - factory interface
 * @export
 */
export const UserPersonPublishingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPersonPublishingApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение статуса расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerGetPublishStatus(id: number, options?: any): AxiosPromise<PublishingStatus> {
            return localVarFp.personPublishControllerGetPublishStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение расписания показов
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerGetSchedule(id: number, options?: any): AxiosPromise<Schedule> {
            return localVarFp.personPublishControllerGetSchedule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание/изменение расписания показов
         * @param {number} id 
         * @param {ScheduleUpdate} scheduleUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerSetSchedule(id: number, scheduleUpdate: ScheduleUpdate, options?: any): AxiosPromise<Schedule> {
            return localVarFp.personPublishControllerSetSchedule(id, scheduleUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Переключение статуса опубликованности анкеты
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: any): AxiosPromise<void> {
            return localVarFp.personPublishControllerTogglePublish(id, publishUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPersonPublishingApi - object-oriented interface
 * @export
 * @class UserPersonPublishingApi
 * @extends {BaseAPI}
 */
export class UserPersonPublishingApi extends BaseAPI {
    /**
     * 
     * @summary Получение статуса расписания показов
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonPublishingApi
     */
    public personPublishControllerGetPublishStatus(id: number, options?: RawAxiosRequestConfig) {
        return UserPersonPublishingApiFp(this.configuration).personPublishControllerGetPublishStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение расписания показов
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonPublishingApi
     */
    public personPublishControllerGetSchedule(id: number, options?: RawAxiosRequestConfig) {
        return UserPersonPublishingApiFp(this.configuration).personPublishControllerGetSchedule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание/изменение расписания показов
     * @param {number} id 
     * @param {ScheduleUpdate} scheduleUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonPublishingApi
     */
    public personPublishControllerSetSchedule(id: number, scheduleUpdate: ScheduleUpdate, options?: RawAxiosRequestConfig) {
        return UserPersonPublishingApiFp(this.configuration).personPublishControllerSetSchedule(id, scheduleUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Переключение статуса опубликованности анкеты
     * @param {number} id 
     * @param {PublishUpdateInput} publishUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonPublishingApi
     */
    public personPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: RawAxiosRequestConfig) {
        return UserPersonPublishingApiFp(this.configuration).personPublishControllerTogglePublish(id, publishUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPersonReviewApi - axios parameter creator
 * @export
 */
export const UserPersonReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Просмотр своего списка запросов на модерацию
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {PersonReviewControllerFindAllStatusEnum} [status] фильтр по статусу запроса на модерацию
         * @param {number} [personId] фильтр по анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personReviewControllerFindAll: async (limit: number, offset: number, status?: PersonReviewControllerFindAllStatusEnum, personId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('personReviewControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('personReviewControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/users/person-reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (personId !== undefined) {
                localVarQueryParameter['personId'] = personId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPersonReviewApi - functional programming interface
 * @export
 */
export const UserPersonReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPersonReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Просмотр своего списка запросов на модерацию
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {PersonReviewControllerFindAllStatusEnum} [status] фильтр по статусу запроса на модерацию
         * @param {number} [personId] фильтр по анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personReviewControllerFindAll(limit: number, offset: number, status?: PersonReviewControllerFindAllStatusEnum, personId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonReviewControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personReviewControllerFindAll(limit, offset, status, personId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonReviewApi.personReviewControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPersonReviewApi - factory interface
 * @export
 */
export const UserPersonReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPersonReviewApiFp(configuration)
    return {
        /**
         * 
         * @summary Просмотр своего списка запросов на модерацию
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {PersonReviewControllerFindAllStatusEnum} [status] фильтр по статусу запроса на модерацию
         * @param {number} [personId] фильтр по анкете
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personReviewControllerFindAll(limit: number, offset: number, status?: PersonReviewControllerFindAllStatusEnum, personId?: number, options?: any): AxiosPromise<PersonReviewControllerFindAll200Response> {
            return localVarFp.personReviewControllerFindAll(limit, offset, status, personId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPersonReviewApi - object-oriented interface
 * @export
 * @class UserPersonReviewApi
 * @extends {BaseAPI}
 */
export class UserPersonReviewApi extends BaseAPI {
    /**
     * 
     * @summary Просмотр своего списка запросов на модерацию
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {PersonReviewControllerFindAllStatusEnum} [status] фильтр по статусу запроса на модерацию
     * @param {number} [personId] фильтр по анкете
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonReviewApi
     */
    public personReviewControllerFindAll(limit: number, offset: number, status?: PersonReviewControllerFindAllStatusEnum, personId?: number, options?: RawAxiosRequestConfig) {
        return UserPersonReviewApiFp(this.configuration).personReviewControllerFindAll(limit, offset, status, personId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const PersonReviewControllerFindAllStatusEnum = {
    New: 'new',
    InProgress: 'in_progress',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;
export type PersonReviewControllerFindAllStatusEnum = typeof PersonReviewControllerFindAllStatusEnum[keyof typeof PersonReviewControllerFindAllStatusEnum];


/**
 * UserPersonVerificationApi - axios parameter creator
 * @export
 */
export const UserPersonVerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение кода верификации для видео
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationControllerCreateVerificationCode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/persons/verification/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка проверочного видео анкеты
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationControllerUpload: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/persons/verification/video`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPersonVerificationApi - functional programming interface
 * @export
 */
export const UserPersonVerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPersonVerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение кода верификации для видео
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationControllerCreateVerificationCode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationControllerCreateVerificationCode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonVerificationApi.verificationControllerCreateVerificationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузка проверочного видео анкеты
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificationControllerUpload(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificationControllerUpload(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonVerificationApi.verificationControllerUpload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPersonVerificationApi - factory interface
 * @export
 */
export const UserPersonVerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPersonVerificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение кода верификации для видео
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationControllerCreateVerificationCode(options?: any): AxiosPromise<VerificationCode> {
            return localVarFp.verificationControllerCreateVerificationCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка проверочного видео анкеты
         * @param {File} [file] File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificationControllerUpload(file?: File, options?: any): AxiosPromise<UploadResponse> {
            return localVarFp.verificationControllerUpload(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPersonVerificationApi - object-oriented interface
 * @export
 * @class UserPersonVerificationApi
 * @extends {BaseAPI}
 */
export class UserPersonVerificationApi extends BaseAPI {
    /**
     * 
     * @summary Получение кода верификации для видео
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonVerificationApi
     */
    public verificationControllerCreateVerificationCode(options?: RawAxiosRequestConfig) {
        return UserPersonVerificationApiFp(this.configuration).verificationControllerCreateVerificationCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка проверочного видео анкеты
     * @param {File} [file] File to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonVerificationApi
     */
    public verificationControllerUpload(file?: File, options?: RawAxiosRequestConfig) {
        return UserPersonVerificationApiFp(this.configuration).verificationControllerUpload(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPersonsApi - axios parameter creator
 * @export
 */
export const UserPersonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание анкеты
         * @param {PersonCreateDto} personCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerCreate: async (personCreateDto: PersonCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personCreateDto' is not null or undefined
            assertParamExists('userPersonControllerCreate', 'personCreateDto', personCreateDto)
            const localVarPath = `/api/v1/users/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userPersonControllerDelete', 'id', id)
            const localVarPath = `/api/v1/users/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пагинированный список своих анкет
         * @param {number} [limit] limit
         * @param {number} [offset] offset
         * @param {string} [search] поиск анкеты по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerFindAll: async (limit?: number, offset?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerGetById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userPersonControllerGetById', 'id', id)
            const localVarPath = `/api/v1/users/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление анкеты
         * @param {number} id 
         * @param {PersonUpdateDto} personUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerUpdate: async (id: number, personUpdateDto: PersonUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userPersonControllerUpdate', 'id', id)
            // verify required parameter 'personUpdateDto' is not null or undefined
            assertParamExists('userPersonControllerUpdate', 'personUpdateDto', personUpdateDto)
            const localVarPath = `/api/v1/users/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPersonsApi - functional programming interface
 * @export
 */
export const UserPersonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPersonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание анкеты
         * @param {PersonCreateDto} personCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPersonControllerCreate(personCreateDto: PersonCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPersonControllerCreate(personCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonsApi.userPersonControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Удаление анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPersonControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPersonControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonsApi.userPersonControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Пагинированный список своих анкет
         * @param {number} [limit] limit
         * @param {number} [offset] offset
         * @param {string} [search] поиск анкеты по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPersonControllerFindAll(limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPersonControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPersonControllerFindAll(limit, offset, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonsApi.userPersonControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPersonControllerGetById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPersonControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonsApi.userPersonControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Обновление анкеты
         * @param {number} id 
         * @param {PersonUpdateDto} personUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPersonControllerUpdate(id: number, personUpdateDto: PersonUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPersonControllerUpdate(id, personUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPersonsApi.userPersonControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPersonsApi - factory interface
 * @export
 */
export const UserPersonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPersonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание анкеты
         * @param {PersonCreateDto} personCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerCreate(personCreateDto: PersonCreateDto, options?: any): AxiosPromise<ReviewRequest> {
            return localVarFp.userPersonControllerCreate(personCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerDelete(id: number, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.userPersonControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Пагинированный список своих анкет
         * @param {number} [limit] limit
         * @param {number} [offset] offset
         * @param {string} [search] поиск анкеты по названию
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerFindAll(limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<UserPersonControllerFindAll200Response> {
            return localVarFp.userPersonControllerFindAll(limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение анкеты
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerGetById(id: number, options?: any): AxiosPromise<PersonDto> {
            return localVarFp.userPersonControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление анкеты
         * @param {number} id 
         * @param {PersonUpdateDto} personUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPersonControllerUpdate(id: number, personUpdateDto: PersonUpdateDto, options?: any): AxiosPromise<ReviewRequest> {
            return localVarFp.userPersonControllerUpdate(id, personUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPersonsApi - object-oriented interface
 * @export
 * @class UserPersonsApi
 * @extends {BaseAPI}
 */
export class UserPersonsApi extends BaseAPI {
    /**
     * 
     * @summary Создание анкеты
     * @param {PersonCreateDto} personCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonsApi
     */
    public userPersonControllerCreate(personCreateDto: PersonCreateDto, options?: RawAxiosRequestConfig) {
        return UserPersonsApiFp(this.configuration).userPersonControllerCreate(personCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление анкеты
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonsApi
     */
    public userPersonControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return UserPersonsApiFp(this.configuration).userPersonControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Пагинированный список своих анкет
     * @param {number} [limit] limit
     * @param {number} [offset] offset
     * @param {string} [search] поиск анкеты по названию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonsApi
     */
    public userPersonControllerFindAll(limit?: number, offset?: number, search?: string, options?: RawAxiosRequestConfig) {
        return UserPersonsApiFp(this.configuration).userPersonControllerFindAll(limit, offset, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение анкеты
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonsApi
     */
    public userPersonControllerGetById(id: number, options?: RawAxiosRequestConfig) {
        return UserPersonsApiFp(this.configuration).userPersonControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление анкеты
     * @param {number} id 
     * @param {PersonUpdateDto} personUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPersonsApi
     */
    public userPersonControllerUpdate(id: number, personUpdateDto: PersonUpdateDto, options?: RawAxiosRequestConfig) {
        return UserPersonsApiFp(this.configuration).userPersonControllerUpdate(id, personUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerSetProfile: async (profileUpdateInput: ProfileUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateInput' is not null or undefined
            assertParamExists('userProfileControllerSetProfile', 'profileUpdateInput', profileUpdateInput)
            const localVarPath = `/api/v1/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.userProfileControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileControllerSetProfile(profileUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.userProfileControllerSetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение данных профиля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerGetProfile(options?: any): AxiosPromise<Profile> {
            return localVarFp.userProfileControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Изменение данных профиля
         * @param {ProfileUpdateInput} profileUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: any): AxiosPromise<Profile> {
            return localVarFp.userProfileControllerSetProfile(profileUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI {
    /**
     * 
     * @summary Получение данных профиля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userProfileControllerGetProfile(options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).userProfileControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Изменение данных профиля
     * @param {ProfileUpdateInput} profileUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public userProfileControllerSetProfile(profileUpdateInput: ProfileUpdateInput, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).userProfileControllerSetProfile(profileUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserSalonPublishingApi - axios parameter creator
 * @export
 */
export const UserSalonPublishingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение статуса опубликованности салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonPublishControllerGetPublishStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonPublishControllerGetPublishStatus', 'id', id)
            const localVarPath = `/api/v1/users/salons/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переключение статуса опубликованности салона
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonPublishControllerTogglePublish: async (id: number, publishUpdateInput: PublishUpdateInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('salonPublishControllerTogglePublish', 'id', id)
            // verify required parameter 'publishUpdateInput' is not null or undefined
            assertParamExists('salonPublishControllerTogglePublish', 'publishUpdateInput', publishUpdateInput)
            const localVarPath = `/api/v1/users/salons/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSalonPublishingApi - functional programming interface
 * @export
 */
export const UserSalonPublishingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSalonPublishingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение статуса опубликованности салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonPublishControllerGetPublishStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishingStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonPublishControllerGetPublishStatus(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonPublishingApi.salonPublishControllerGetPublishStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Переключение статуса опубликованности салона
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salonPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salonPublishControllerTogglePublish(id, publishUpdateInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonPublishingApi.salonPublishControllerTogglePublish']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserSalonPublishingApi - factory interface
 * @export
 */
export const UserSalonPublishingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSalonPublishingApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение статуса опубликованности салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonPublishControllerGetPublishStatus(id: number, options?: any): AxiosPromise<PublishingStatus> {
            return localVarFp.salonPublishControllerGetPublishStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Переключение статуса опубликованности салона
         * @param {number} id 
         * @param {PublishUpdateInput} publishUpdateInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salonPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: any): AxiosPromise<void> {
            return localVarFp.salonPublishControllerTogglePublish(id, publishUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSalonPublishingApi - object-oriented interface
 * @export
 * @class UserSalonPublishingApi
 * @extends {BaseAPI}
 */
export class UserSalonPublishingApi extends BaseAPI {
    /**
     * 
     * @summary Получение статуса опубликованности салона
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonPublishingApi
     */
    public salonPublishControllerGetPublishStatus(id: number, options?: RawAxiosRequestConfig) {
        return UserSalonPublishingApiFp(this.configuration).salonPublishControllerGetPublishStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Переключение статуса опубликованности салона
     * @param {number} id 
     * @param {PublishUpdateInput} publishUpdateInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonPublishingApi
     */
    public salonPublishControllerTogglePublish(id: number, publishUpdateInput: PublishUpdateInput, options?: RawAxiosRequestConfig) {
        return UserSalonPublishingApiFp(this.configuration).salonPublishControllerTogglePublish(id, publishUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserSalonsApi - axios parameter creator
 * @export
 */
export const UserSalonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание салона
         * @param {SalonCreateDto} salonCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerCreate: async (salonCreateDto: SalonCreateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'salonCreateDto' is not null or undefined
            assertParamExists('userSalonControllerCreate', 'salonCreateDto', salonCreateDto)
            const localVarPath = `/api/v1/users/salons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salonCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userSalonControllerDelete', 'id', id)
            const localVarPath = `/api/v1/users/salons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пагинированный список своих салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerFindAll: async (limit: number, offset: number, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userSalonControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userSalonControllerFindAll', 'offset', offset)
            const localVarPath = `/api/v1/users/salons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (districtId !== undefined) {
                localVarQueryParameter['districtId'] = districtId;
            }

            if (metroStationIds) {
                localVarQueryParameter['metroStationIds'] = metroStationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerFindById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userSalonControllerFindById', 'id', id)
            const localVarPath = `/api/v1/users/salons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление салона
         * @param {number} id 
         * @param {SalonUpdateDto} salonUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerUpdate: async (id: number, salonUpdateDto: SalonUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userSalonControllerUpdate', 'id', id)
            // verify required parameter 'salonUpdateDto' is not null or undefined
            assertParamExists('userSalonControllerUpdate', 'salonUpdateDto', salonUpdateDto)
            const localVarPath = `/api/v1/users/salons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salonUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSalonsApi - functional programming interface
 * @export
 */
export const UserSalonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSalonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание салона
         * @param {SalonCreateDto} salonCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSalonControllerCreate(salonCreateDto: SalonCreateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSalonControllerCreate(salonCreateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonsApi.userSalonControllerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Удаление салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSalonControllerDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSalonControllerDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonsApi.userSalonControllerDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Пагинированный список своих салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSalonControllerFindAll(limit: number, offset: number, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSalonControllerFindAll200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSalonControllerFindAll(limit, offset, search, cityId, districtId, metroStationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonsApi.userSalonControllerFindAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Получение салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSalonControllerFindById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSalonControllerFindById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonsApi.userSalonControllerFindById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Обновление салона
         * @param {number} id 
         * @param {SalonUpdateDto} salonUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSalonControllerUpdate(id: number, salonUpdateDto: SalonUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalonDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSalonControllerUpdate(id, salonUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSalonsApi.userSalonControllerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserSalonsApi - factory interface
 * @export
 */
export const UserSalonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSalonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание салона
         * @param {SalonCreateDto} salonCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerCreate(salonCreateDto: SalonCreateDto, options?: any): AxiosPromise<SalonDto> {
            return localVarFp.userSalonControllerCreate(salonCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerDelete(id: number, options?: any): AxiosPromise<SalonDto> {
            return localVarFp.userSalonControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Пагинированный список своих салонов
         * @param {number} limit limit
         * @param {number} offset offset
         * @param {string} [search] 
         * @param {number} [cityId] 
         * @param {number} [districtId] 
         * @param {Array<number>} [metroStationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerFindAll(limit: number, offset: number, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: any): AxiosPromise<UserSalonControllerFindAll200Response> {
            return localVarFp.userSalonControllerFindAll(limit, offset, search, cityId, districtId, metroStationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение салона
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerFindById(id: number, options?: any): AxiosPromise<SalonDto> {
            return localVarFp.userSalonControllerFindById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление салона
         * @param {number} id 
         * @param {SalonUpdateDto} salonUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSalonControllerUpdate(id: number, salonUpdateDto: SalonUpdateDto, options?: any): AxiosPromise<SalonDto> {
            return localVarFp.userSalonControllerUpdate(id, salonUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSalonsApi - object-oriented interface
 * @export
 * @class UserSalonsApi
 * @extends {BaseAPI}
 */
export class UserSalonsApi extends BaseAPI {
    /**
     * 
     * @summary Создание салона
     * @param {SalonCreateDto} salonCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonsApi
     */
    public userSalonControllerCreate(salonCreateDto: SalonCreateDto, options?: RawAxiosRequestConfig) {
        return UserSalonsApiFp(this.configuration).userSalonControllerCreate(salonCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление салона
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonsApi
     */
    public userSalonControllerDelete(id: number, options?: RawAxiosRequestConfig) {
        return UserSalonsApiFp(this.configuration).userSalonControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Пагинированный список своих салонов
     * @param {number} limit limit
     * @param {number} offset offset
     * @param {string} [search] 
     * @param {number} [cityId] 
     * @param {number} [districtId] 
     * @param {Array<number>} [metroStationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonsApi
     */
    public userSalonControllerFindAll(limit: number, offset: number, search?: string, cityId?: number, districtId?: number, metroStationIds?: Array<number>, options?: RawAxiosRequestConfig) {
        return UserSalonsApiFp(this.configuration).userSalonControllerFindAll(limit, offset, search, cityId, districtId, metroStationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение салона
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonsApi
     */
    public userSalonControllerFindById(id: number, options?: RawAxiosRequestConfig) {
        return UserSalonsApiFp(this.configuration).userSalonControllerFindById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление салона
     * @param {number} id 
     * @param {SalonUpdateDto} salonUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSalonsApi
     */
    public userSalonControllerUpdate(id: number, salonUpdateDto: SalonUpdateDto, options?: RawAxiosRequestConfig) {
        return UserSalonsApiFp(this.configuration).userSalonControllerUpdate(id, salonUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



