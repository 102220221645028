import { store } from 'quasar/wrappers'
import { createPinia } from 'pinia'
// import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
import { Router } from 'vue-router'
import cloneDeep from 'lodash.clonedeep'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { markRaw } from 'vue'
import router from 'src/router'

/*
 * When adding new properties to stores, you should also
 * extend the `PiniaCustomProperties` interface.
 * @see https://pinia.vuejs.org/core-concepts/plugins.html#typing-new-store-properties
 */
declare module 'pinia' {
	export interface PiniaCustomProperties {
		readonly router: Router
	}
}

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export const pinia = createPinia()

// @ts-ignore
const piniaPluginResetStore = ({ store }) => {
	const initialState = cloneDeep(store.$state)
	store.$reset = () => store.$patch(cloneDeep(initialState))
}
// @ts-ignore
const piniaPluginUseRouter = ({ store }) => {
	// @ts-ignore
	store.router = markRaw(router())
}

export default store((/* { ssrContext } */) => {
	// You can add Pinia plugins here
	// const installPersistedStatePlugin = createPersistedStatePlugin()
	// pinia.use((context) => installPersistedStatePlugin(context))
	pinia.use(piniaPluginResetStore)
	pinia.use(piniaPluginPersistedstate)
	pinia.use(piniaPluginUseRouter)

	return pinia
})
