import { boot } from 'quasar/wrappers'
import * as Sentry from '@sentry/vue'

export default boot(({ app /* router */ }) => {
	Sentry.init({
		app,
		dsn: process.env.SENTRY_DSN,
		enabled: process.env.NODE_ENV !== 'development',
		attachStacktrace: true,
		attachProps: true,
		trackComponents: true,
		tracesSampleRate: 1.0,
		logErrors: process.env.NODE_ENV === 'development',
	})
	Sentry.setTag('server_env', process.env.SERVER_ENV || 'unknown')
})
