import { RouteRecordRaw } from 'vue-router'

export default [
	{
		path: '/',
		component: () => import('layouts/main-layout.vue'),
		children: [
			{
				path: '',
				name: 'main',
				component: () => import('pages/public/main-page.vue'),
			},
			{
				path: '/:id(\\d+)',
				name: 'person',
				component: () => import('pages/public/person-page.vue'),
			},
			{
				path: '/salon/:id(\\d+)',
				name: 'salon',
				component: () => import('pages/public/salon-page.vue'),
			},
			{
				path: '/about',
				name: 'about',
				component: () => import('pages/public/about.vue'),
			},
			{
				path: '/:slug+',
				name: 'slugs',
				component: () => import('pages/public/search-page.vue'),
				props: true,
			},
		],
	},
	{
		path: '/healthcheck',
		component: () => import('pages/common/health/index.vue'),
	},
] satisfies RouteRecordRaw[]
