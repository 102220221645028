import { RouteRecordRaw } from 'vue-router'

export default [
	{
		path: '/personal',
		component: () => import('layouts/personal-layout.vue'),
		meta: { needAuth: true },
		children: [
			{
				path: '',
				component: () => import('pages/personal/empty-page.vue'),
			},
			{
				path: 'dashboard',
				component: () => import('pages/personal/dashboard/index.vue'),
			},
			{
				path: 'profile',
				component: () => import('pages/personal/user-profile/index.vue'),
			},
			{
				path: 'cards',
				component: () => import('pages/personal/card/index.vue'),
			},
			{
				path: 'salons',
				component: () => import('pages/personal/salon/index.vue'),
			},
			{
				path: 'notifications',
				component: () => import('pages/personal/notification/index.vue'),
			},
			{
				path: 'support',
				component: () => import('pages/personal/support/index.vue'),
			},
		],
	},
] satisfies RouteRecordRaw[]
