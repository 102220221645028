import { boot } from 'quasar/wrappers'
import axios, { Axios, AxiosError, AxiosInstance } from 'axios'
import { useAuthStore } from 'stores/auth.store'
import { useUiStore } from 'stores/ui.store'
import {
	AuthApi,
	BalanceReminingApi,
	BannersApi,
	CommentsApi,
	ContactsApi,
	CustomerProfileApi,
	I18nApi,
	ImageApi,
	MediaApi,
	MetaApi,
	NotificationsApi,
	PersonsApi,
	ProfileLanguageEnum,
	RateApi,
	RefsApi,
	ReplenishApi,
	SalonsApi,
	TransactionsApi,
	UserPersonFriendsApi,
	UserPersonPublishingApi,
	UserPersonReviewApi,
	UserPersonsApi,
	UserPersonVerificationApi,
	UserProfileApi,
	UserSalonPublishingApi,
	UserSalonsApi,
} from 'src/common/axios-client'

import { Headers } from '@interfaces/headers'
import { UserJwt } from '@interfaces/jwt.interface'
import { jwtDecode } from 'jwt-decode'
import { Mutex } from 'async-mutex'

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$axios: Axios
		$api: AxiosInstance
	}
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
// const api = axios.create({ baseURL: process.env.API_URL })

interface IError {
	message: string
	stack?: string
}

const baseURL = process.env.SERVER
	? process.env.PRIVATE_API_URL
	: process.env.API_URL

const api = axios.create({ baseURL })

export const API = {
	authApi: new AuthApi(undefined, '/', api),
	balanceReminingApi: new BalanceReminingApi(undefined, '/', api),
	bannersApi: new BannersApi(undefined, '/', api),
	commentsApi: new CommentsApi(undefined, '/', api),
	contactsApi: new ContactsApi(undefined, '/', api),
	customerProfileApi: new CustomerProfileApi(undefined, '/', api),
	rateApi: new RateApi(undefined, '/', api),
	imageApi: new ImageApi(undefined, '/', api),
	mediaApi: new MediaApi(undefined, '/', api),
	metaApi: new MetaApi(undefined, '/', api),
	notificationsApi: new NotificationsApi(undefined, '/', api),
	personsApi: new PersonsApi(undefined, '/', api),
	salonsApi: new SalonsApi(undefined, '/', api),
	replenishApi: new ReplenishApi(undefined, '/', api),
	transactionsApi: new TransactionsApi(undefined, '/', api),
	userPersonFriendsApi: new UserPersonFriendsApi(undefined, '/', api),
	userPersonPublishingApi: new UserPersonPublishingApi(undefined, '/', api),
	userPersonReviewApi: new UserPersonReviewApi(undefined, '/', api),
	userPersonVerificationApi: new UserPersonVerificationApi(undefined, '/', api),
	userPersonsApi: new UserPersonsApi(undefined, '/', api),
	userSalonPublishingApi: new UserSalonPublishingApi(undefined, '/', api),
	userSalonsApi: new UserSalonsApi(undefined, '/', api),
	userProfileApi: new UserProfileApi(undefined, '/', api),
	refsApi: new RefsApi(undefined, '/', api),
	i18n: new I18nApi(undefined, '/', api),
} as const

export default boot(({ app }) => {
	// for use inside Vue files (Options API) through this.$axios and this.$api

	app.config.globalProperties.$axios = axios
	// ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
	//       so you won't necessarily have to import axios in each vue file

	app.config.globalProperties.$api = api
	// ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
	//       so you can easily perform requests against your app's API

	const authStore = useAuthStore()
	const uiStore = useUiStore()

	const mutex = new Mutex()

	const getLanguage = (): string | undefined => {
		const token = authStore.tokens?.accessToken

		const menuLanguage = uiStore.locale

		if (token) {
			try {
				const user = jwtDecode<UserJwt>(token)
				return user.lang
			} catch (err) {
				console.error(err)
				return menuLanguage
			}
		}

		return menuLanguage
	}

	api.interceptors.request.use(
		async (config) => {
			if (authStore?.tokens?.accessToken)
				config.headers.set(
					Headers.authorization,
					`Bearer ${authStore.tokens.accessToken}`,
				)

			const language = getLanguage() ?? ProfileLanguageEnum.Ru
			config.headers.set(Headers.language, language)

			return config
		},
		(error) => {
			void Promise.reject(error)
		},
	)

	// Response interceptor for API calls
	api.interceptors.response.use(
		(response) => {
			if (response.data.error) throw uiStore.showError(response.data.error)
			return response
		},
		async (error: AxiosError<IError>) => {
			const { response, config: originalRequest } = error

			if (!response) {
				// backend down
				throw uiStore.showError(
					// TODO: i18n
					'Сервер недоступен',
				)
			}

			const { status } = response

			if (originalRequest && originalRequest.headers.retry) {
				await mutex.waitForUnlock()
				return api(originalRequest)
			}

			if (
				status === 401 &&
				authStore?.tokens?.refreshToken &&
				originalRequest
			) {
				const locked = mutex.isLocked()
				if (locked) {
					originalRequest.headers.retry = true
					await mutex.waitForUnlock()
					return api(originalRequest)
				}

				/** try to refresh tokens */
				originalRequest.headers.retry = true
				const release = await mutex.acquire()

				await authStore.refreshAccessToken().finally(() => {
					release()
				})

				return api(originalRequest)
			}

			if (error.response?.data?.message) {
				throw uiStore.showError(
					JSON.stringify(error?.response?.data?.message, null, 2),
				)
			}
			throw uiStore.showError(`Error ${error.status}: ${error.message}`)
		},
	)
})

export { api }
