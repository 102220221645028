import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useQuasar } from 'quasar'
import langRu from 'quasar/lang/ru'
import { useUpdateChecker } from './stores/update-checker'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const quasar = useQuasar()
useUpdateChecker()

// @ts-ignore
quasar.lang.set(langRu)

// const host = process.env.SERVER
// 	? useSSRContext()?.req.hostname
// 	: window.location.hostname

console.log(`Running on SERVER_ENV: ${process.env.SERVER_ENV}`)

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})