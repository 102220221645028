import { RouteRecordRaw } from 'vue-router'

export default [
	{
		path: '/auth/login',
		component: () => import('layouts/main-layout.vue'),
		children: [
			{
				path: '',
				component: () => import('pages/auth/login/login-page.vue'),
			},
		],
	},
	{
		path: '/auth/logout',
		component: () => import('layouts/personal-layout.vue'),
		children: [
			{
				path: '',
				component: () => import('pages/auth/logout-page.vue'),
			},
		],
	},
] satisfies RouteRecordRaw[]
