import { defineStore } from 'pinia'
import {
	EmailSignInInput,
	EmailSignUpInput,
	Profile,
	ProfileUpdateInput,
	Tokens,
} from 'src/common/axios-client'
import { API } from 'boot/axios'
import { Notify } from 'quasar'

interface AuthStore {
	user: Profile | null
	tokens: Tokens | null
}

export const useAuthStore = defineStore('authStore', {
	state: (): AuthStore => ({
		user: null,
		tokens: null,
	}),

	getters: {
		isLoggedIn(): boolean {
			return !!this.tokens?.accessToken
		},
	},

	actions: {
		async logout() {
			this.user = {} as Profile
			this.tokens = {} as Tokens
		},

		async loginByEmail({ login, password }: EmailSignInInput): Promise<void> {
			API.authApi
				.emailControllerSignIn({
					login,
					password,
				})
				.then(async (res) => {
					if (res.data) {
						this.tokens = res.data
						await this.setUserDataByToken()
						this.router.push('/personal')
					}
				})
		},

		async registerByEmail(input: EmailSignUpInput) {
			API.authApi
				.emailControllerSignUp(input)
				.then(() => {
					// TODO: i18n
					Notify.create({
						message: 'Отправлено письмо с данными для входа',
						type: 'positive',
					})
				})
				.catch(() => {
					// TODO: i18n
					Notify.create({
						message: 'Не удалось отправить письмо, свяжитесь с техподдержкой',
						type: 'negative',
					})
				})
		},

		async refreshAccessToken(): Promise<void> {
			if (!this.tokens) {
				await this.logout()
				return
			}

			await API.authApi
				.authControllerRefresh({
					refreshToken: this.tokens.refreshToken,
				})
				.then(async (res) => {
					this.tokens = res.data
					await this.setUserDataByToken()
				})
				.catch(async () => {
					await this.logout()
					Notify.create({
						// TODO: i18n
						message: 'Не удалось автоматически войти в систему',
						type: 'negative',
					})
				})
		},

		// TODO: вынести из authStore в profileStore, т.к. к auth не имеет отношения
		async saveMyUser(userUpdated: ProfileUpdateInput) {
			const { data } =
				await API.userProfileApi.userProfileControllerSetProfile(userUpdated)
			this.$state.user = data
			return this.$state.user
		},

		// TODO: вынести из authStore в profileStore, т.к. к auth не имеет отношения
		async setUserDataByToken(): Promise<void> {
			await API.userProfileApi
				.userProfileControllerGetProfile()
				.then((response) => {
					this.user = response.data
				})
		},
	},
	persist: true,
})
