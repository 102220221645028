export default {
	locale: 'Русский',
	failed: 'Не получилось',
	success: 'Получилось!',
	appTitle: 'ashoo',
	common: { back: 'Назад' },
	usertype: { customer: 'Посетитель', user: 'Рекламодатель' },
	language: { english: 'Английский', russian: 'Русский' },
	login: {
		tabTitle: 'Вход',
		tabRegistration: 'Регистрация',
		title: 'Привет!',
		byEmail: 'Вход',
		byPhone: 'Телефон',
		appInfo: 'Информация',
		emailLabel: 'Электронная почта',
		nickname: 'Ник',
		phoneLabel: 'Телефон',
		language: 'Язык',
		timezone: 'Часовой пояс',
		country: 'Страна',
		passwordLabel: 'Пароль',
		makeCall: 'Сделать звонок',
		sendSMS: 'Отправить СМС',
		codeSmsLabel: 'Код из СМС',
		codeCallLabel: 'Последние 4 цифры номера',
		loginButton: 'Войти',
		registerButton: 'Регистрация',
		backToHome: 'Вернуться на главную',
		activating: 'Активация..',
		useActivateLinkInEmail: 'Отправленная электронная почта',
		checkEmailButton: 'Далее',
		logout: 'Выйти',
		healthLink: 'healthLink',
	},
	logout: {
		title: 'Выйти',
		confirmText: 'Выйти из аккаунта',
		cancel: 'Нет, не выходить',
		proceed: 'Выйти',
	},
	updater: {
		messagePre: 'Вышла новая версия! ',
		messageLink: 'Обновите',
		messagePost: 'страницу',
	},
	mainPage: {
		title: 'Добро пожаловать в АШУ!',
		subtitle: 'Всё, что нужно для ведения бизнеса',
		pleaseLogin: 'Пожалуйста, войдите',
	},
	cookies: {
		message:
			'Мы используем файлы cookie: технические, аналитические, маркетинговые и другие. ',
		acceptButton: 'Принять',
	},
	errors: {
		'nothing-here':
			'Мы тоже пытались найти эту страницу, но её просто не существует',
		unknownError: 'Непонятная ошибка',
	},
	menu: {
		Dashboard: 'Дашборд',
		References: 'Справочники',
		Products: 'Продукты',
		'User groups': 'Группы пользователей',
		Users: 'Пользователи',
		Card: 'Анкеты',
		Salon: 'Салоны',
		Files: 'Файлы',
		Countries: 'Страны',
		'Payment methods': 'Способы оплаты',
		Reports: 'Отчёты',
		Orders: 'Заказы',
		Health: 'Здоровье',
		Support: 'Поддержка',
		About: 'О сервисе',
		Profile: 'Профиль',
		'Trade objects': 'Торговые объекты',
		'Trade areas': 'Залы',
		Table: 'Стол',
		Tables: 'Столы',
		Currency: 'Валюта',
		Feedback: 'Отзывы',
		Notifications: 'Уведомления',
		'Work zones': 'Рабочие зоны',
		Stores: 'магазины',
		Scan: 'Сканировать',
		Cart: 'Корзина',
		'Product categories': 'Категории продукта',
		Cashes: 'Кассы',
		Сart: 'Сart',
		Admins: 'Admins',
	},
	stopScanning: 'прекратить сканирование',
	name: 'Имя',
	yourName: 'Ваше имя',
	phone: 'Телефон',
	orderHistory: 'История заказов',
	save: 'Сохранять',
	cartPage: {
		receipts: 'Квитанции',
		addBag: 'Добавить в корзину',
		total: 'Итого',
		goToPayment: 'Перейти к оплате',
		addItem: 'Добавить элемент',
		payOnCash: 'Оплатить на кассе',
		payByKaspi: 'Оплата через Каспи',
		receipt: 'Чек',
		thereIsNoItemsToShow: 'Тут ничего нет',
		yourOrderNumberIs: 'Номер вашего заказа',
		showThisNumberToCashierToMakePayment:
			'Покажите этот номер кассиру для оплаты.',
		stopPurchase: 'Остановить покупку',
		methodOfPayment: 'Метод оплаты',
		byCash: 'Наличными',
		listOfPurchase: 'Список покупок',
	},
	question: 'Вопрос',
	Scan: 'Сканировать',
	cartPageTitle: 'Ваша корзина',
	profilePageTitle: 'Ваш профиль',
	more: 'Подробнее',
	clickToScan: 'Нажмите, чтобы отсканировать код',
	profilePage: {
		pleaseEnterCorrectNumber: 'Пожалуйста, введите правильный номер телефона',
		enterCorrectInformation: 'Пожалуйста, введите правильную информацию',
		messageOfToSeeYourOrderHistory: 'messageOfToSeeYourOrderHistory',
	},
	messageOfToSeeYourOrderHistory: 'messageOfToSeeYourOrderHistory',
	Сart: 'Сart',
	methodOfPayment: 'methodOfPayment',
	byCash: 'byCash',
	listOfpurchase: 'listOfpurchase',
	Search: 'Имя, описание',
}
