export default {
	locale: 'English',
	failed: 'Action failed',
	success: 'Action was successful',
	appTitle: 'ashoo',
	common: { back: 'Go back' },
	usertype: {
		customer: 'Customer',
		user: 'Advertiser',
	},
	language: {
		english: 'English',
		russian: 'Russian',
	},
	login: {
		tabTitle: 'Sign in',
		tabRegistration: 'Sign up',
		title: 'Welcome!',
		byEmail: 'Login',
		byPhone: 'Phone',
		appInfo: 'App info',
		emailLabel: 'Email',
		nickname: 'Nick name',
		phoneLabel: 'Phone number',
		language: 'Language',
		timezone: 'Timezone',
		country: 'Country',
		passwordLabel: 'Password',
		makeCall: 'Make a verify call',
		sendSMS: 'Send code in SMS',
		codeSmsLabel: 'SMS-code',
		codeCallLabel: 'Last 4 digits',
		loginButton: 'Log in',
		registerButton: 'Register',
		backToHome: 'Back to main page',
		activating: 'Activating..',
		useActivateLinkInEmail: 'Activation email was sent.',
		checkEmailButton: 'Continue',
		logout: 'Logout',
		healthLink: 'healthLink',
	},
	logout: {
		title: 'Logout',
		confirmText: 'Logout',
		cancel: 'No',
		proceed: 'Logout',
	},
	updater: {
		messagePre: 'Update is available! Please save your changes and',
		messageLink: 'Refresh',
		messagePost: 'the page',
	},
	mainPage: {
		title: 'Welcome to ASHOO!',
		subtitle: 'All you need for your business',
		pleaseLogin: 'Please login',
	},
	cookies: {
		message:
			'We use cookies: technical, analytical, marketing and others. They are necessary for the optimal operation of sites and services, help us to recognize you and store your preferences.',
		acceptButton: 'Accept',
	},
	errors: {
		'nothing-here': 'Oops. Nothing here...',
		unknownError: 'Unknown error',
	},
	menu: {
		Dashboard: 'Dashboard',
		References: 'References',
		Products: 'Products',
		'User groups': 'User groups',
		Users: 'Users',
		Card: 'Cards',
		Salon: 'Salons',
		Files: 'Files',
		Countries: 'Countries',
		'Payment methods': 'Payment methods',
		Reports: 'Reports',
		Orders: 'Orders',
		Health: 'Health',
		Support: 'Support',
		About: 'About',
		Profile: 'Profile',
		'Trade objects': 'Trade objects',
		'Trade areas': 'Trade areas',
		Table: 'Table',
		Tables: 'Tables',
		Currency: 'Currency',
		Feedback: 'Feedback',
		Notifications: 'Notifications',
		'Work zones': 'Work zones',
		Stores: 'Stores',
		Scan: 'Scan',
		Cart: 'Cart',
		'Product categories': 'Product categories',
		Cashes: 'Cashes',
		Сart: 'Сart',
		Admins: 'Admins',
	},
	stopScanning: 'Stop scanning',
	name: 'Name',
	yourName: 'Your name',
	phone: 'Phone number',
	orderHistory: 'Order history',
	save: 'Save',
	cartPage: {
		receipts: 'Receipts',
		addBag: 'Add to bag',
		total: 'Total',
		goToPayment: 'Go to payment',
		addItem: 'Add item',
		payOnCash: 'Pay on cash',
		payByKaspi: 'Pay by Kaspi',
		receipt: 'Receipt',
		thereIsNoItemsToShow: 'There is no items to show',
		yourOrderNumberIs: 'Your order number is',
		showThisNumberToCashierToMakePayment:
			'Show this number to cashier to make payment',
		stopPurchase: 'Stop purchase',
		methodOfPayment: 'Method of payment',
		byCash: 'By cash',
		listOfPurchase: 'List of purchase',
	},
	question: 'question',
	Scan: 'Scan',
	cartPageTitle: 'Your cart',
	profilePageTitle: 'Your profile',
	more: 'More',
	clickToScan: 'Click to scan code',
	profilePage: {
		pleaseEnterCorrectNumber: 'Please enter correct phone number',
		enterCorrectInformation: 'Please enter correct information',
		messageOfToSeeYourOrderHistory: 'messageOfToSeeYourOrderHistory',
	},
	messageOfToSeeYourOrderHistory: 'messageOfToSeeYourOrderHistory',
	Сart: 'Сart',
	methodOfPayment: 'methodOfPayment',
	byCash: 'byCash',
	listOfpurchase: 'listOfpurchase',
	Search: 'Name, description',
}
