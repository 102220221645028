import { Dark, Notify, QNotifyCreateOptions, Screen } from 'quasar'
// @ts-ignore
// import Notify from 'quasar/src/plugins/notify/Notify'
// @ts-ignore
// import Dark from 'quasar/src/plugins/dark/Dark'
import { defineStore } from 'pinia'
import { Composer } from 'vue-i18n'

export interface ITab {
	title: string | any
	route: string
	parent?: string
}

export const useUiStore = defineStore('ui', {
	state: () => ({
		$i18n: {} as any,
		locale: 'ru',
		darkMode: false,
		personSidebarOpened: true,
		mainSidebarOpened: false,
		isCookiesAccepted: false,
		tabs: [] as ITab[],
		activeRoute: '',
	}),

	getters: {
		i18n(): Composer {
			return this.$i18n.global
		},
		uiMode(): 'unknown' | 'mobile' | 'desktop' {
			if (Screen.width === 0) return 'unknown'
			if (Screen.width > 1080) return 'desktop'
			return 'mobile'
		},
	},
	actions: {
		setDarkMode(setDark: boolean) {
			Dark.set(setDark)
			this.darkMode = Dark.isActive
		},

		toggleDarkMode() {
			Dark.toggle()
			this.darkMode = Dark.isActive
		},

		showError(e: any) {
			const message =
				e.response?.data?.error?.message ||
				e.response?.data?.message ||
				e.message ||
				e.toString() ||
				e
			console.error(e)
			// const message = this.i18n.t('errors.unknownError')
			Notify.create({
				color: 'red-5',
				textColor: 'white',
				iconColor: 'white',
				icon: 'error',
				timeout: 2000,
				message,
			})
			return message
		},

		showSuccess(msg?: string) {
			Notify.create({
				color: 'green-5',
				textColor: 'white',
				icon: 'info',
				message: msg || this.$i18n.t('success'),
			})
		},

		showMessage(opts: QNotifyCreateOptions | string) {
			Notify.create(opts)
		},

		addTab(newTab: ITab) {
			const exists = this.tabs.filter((t) => t.route === newTab.route)[0]
			if (exists) this.router.push(exists.route)
			else {
				if (typeof newTab.title === 'function') newTab.title = newTab.title()
				this.tabs.push(newTab)
				this.router.push(newTab.route)
			}
		},

		closeTab(route: string) {
			const index = this.tabs.findIndex((i) => i.route === route)
			if (index >= 0) {
				if (this.tabs[index].parent)
					this.router.push(this.tabs[index].parent as string)
				else if (index > 0) {
					this.router.push(this.tabs[index - 1].route)
				} else if (this.tabs.length > 1) {
					this.router.push(this.tabs[index + 1].route)
				} else this.router.push('/')
				this.tabs.splice(index, 1)
			}
		},
	},
	persist: true,
})
